<div class="become-vendor">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="white">Become a Vendor</h1>
            </div>
        </div>
    </div>
</div>

<!-- become-vendor-form -->
<div class="container form">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="form-group col-md-12 become-vendor-text">
                    <p>Companies and Individual Businesses are looking for Warehousing services. How are they going to find YOU?
                    </p>
                    <ul>
                        <li>ACTIVE PROSPECTS find US.</li>
                        <li>They personally CONTACT Us, We QUALIFY them, and MATCH them to you as per their Business needs.</li>
                        <li>YOUR VALUE – Grow Your Sales Pipeline without cold calls and purchased lists.</li>
                    </ul>
                    <div class="sub-text">
                        <p>Do you own a Warehousing Company and you’d like to see more <b>Warehousing Leads</b>? Wouldn’t it be nice to come into the office in the morning and get a potential inquiry waiting to rent your Warehouse Space?</p>
                        <p>We can help you grow your sales pipeline with qualified prospects looking to outsource with a company like yours. And we’re the only Warehousing Marketplace of its kind that is working in this industry – so we know the specific
                            challenges that you face day to day as you seek new business.</p>
                        <p>If you’re a reputable company, you can become a vendor on our service and receive enquiries and project scope from companies that have been pre-qualified by our management team. You simply share rates of your Warehouse Space with
                            takzin.com and we display them on our listing page for that region. We do all of the filtering, so we send you the enquiries that best match your organization’s capabilities.</p>
                        <p>Thank you for choosing takzin.com as your partner!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row message-form-row">
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-10">
            <h1 class="become-vendor-form-header">Become a Vendor</h1>
            <form [formGroup]="becomeVendorForm" (ngSubmit)="onSubmit(becomeVendorForm.value)">

                <!--CompanyName-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Company Name
                        </label>
                    <input type="text" value="" id="companyName" formControlName="companyName" class="form-control" required>
                    <div *ngIf="companyName!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="companyName!.errors!.required">
                            Company Name is required.
                        </div>
                    </div>
                </div>

                <!--Name-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Name
                        </label>
                    <input type="text" value="" id="name" formControlName="name" class="form-control" required>
                    <div *ngIf="name!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="name!.errors!.required">
                            Name is required.
                        </div>
                    </div>
                </div>

                <!--Email-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Email
                        </label>
                    <input type="text" value="" id="email" formControlName="email" placeholder="yourname@example.com" class="form-control" required>
                    <div *ngIf="email!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="email!.errors!.required">
                            Email is required.
                        </div>
                        <div *ngIf="email!.errors!.pattern">
                            Email is invalid.
                        </div>
                    </div>
                </div>

                <!--Contact Number-->
                <div class="form-group">
                    <label class="takzin-modal-control-label">
                            Contact Number
                        </label>
                    <input type="text" inputmode="numeric" pattern="[0-9]*" digitOnly autocomplete="off" value="" id="contactNo" formControlName="contactNo" class="form-control">
                    <div *ngIf="contactNo!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="contactNo!.errors">
                            Contact Number can only be numbers from 0-9.
                        </div>
                    </div>
                </div>

                <!--Website-->
                <div class="form-group">
                    <label class="takzin-modal-control-label">
                            Website
                        </label>
                    <input type="text" value="" id="website" formControlName="website" class="form-control">
                </div>

                <!--City-->
                

                <!--Emirate-->
                <div class="form-group">
                    <label class="takzin-modal-control-label">
                        Emirate
                        </label>
                    <input type="text" value="" id="emirate" formControlName="emirate" class="form-control">
                </div>

                <div class="form-group">
                    <label class="takzin-modal-control-label">
                        Region/Area 
                        </label>
                    <input type="text" value="" id="city" formControlName="city" class="form-control">
                </div>

                <button type="submit" class="takzin-button-primary takzin-modal-button">Let's Go!</button>
                <br />
                <div class="terms-text">
                    <small>By clicking the above button,
                        you agree to our <a routerLink="/terms" target="_blank" routerLinkActive="active" alt='Terms of Service'>Terms of Service</a> and have read and understood our <a routerLink="/privacy" target="_blank" routerLinkActive="active" alt="Privacy Policy">Privacy Policy.</a></small>
                </div>
            </form>
        </div>
        <div class="col-md-2">&nbsp;</div>
    </div>
</div>