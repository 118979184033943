import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VendorDetailsModel } from 'src/app/models';
import { EmailService, SEOService } from 'src/app/services';
import { EmailTemplate } from 'src/assets/templates/email.template';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'takzin-become-vendor',
  templateUrl: './become-vendor.component.html',
  styleUrls: ['./become-vendor.component.scss']
})
export class BecomeVendorComponent implements OnInit {

  becomeVendorForm: FormGroup;
  showValidationMessages: boolean = false;

  get email() {
    return this.becomeVendorForm!.get('email');
  }

  get name() {
    return this.becomeVendorForm!.get('name');
  }

  get companyName() {
    return this.becomeVendorForm!.get('companyName');
  }

  get contactNo() {
    return this.becomeVendorForm!.get('contactNo');
  }

  get website() {
    return this.becomeVendorForm!.get('website');
  }

  get city() {
    return this.becomeVendorForm!.get('city');
  }

  constructor(
    private readonly emailService: EmailService,
    private readonly router: Router,
    private readonly el: ElementRef,
    private readonly seoService: SEOService
  ) {
    this.becomeVendorForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      name: new FormControl('', [
        Validators.required,
      ]),
      companyName: new FormControl('', [
        Validators.required,
      ]),
      contactNo: new FormControl('', [
        Validators.pattern('^[0-9]*$')
      ]),
      website: new FormControl('', []),
      city: new FormControl('', []),
      emirate: new FormControl('', []),
    });
   }

  ngOnInit(): void {
    const pageTitle = 'takzin | Become A Vendor for Warehouse Storage and Transport | Dubai | UAE';
    this.seoService.updateTitle(pageTitle);
    const pageDescription = 'Become A Vendor with takzin. Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs.';
    this.seoService.updateDescription(pageDescription);
  }

  onSubmit(vendorDetails: VendorDetailsModel) {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid[formControlName]');
    if (invalidControl) {
      invalidControl.focus();
    }
    
    if (this.becomeVendorForm.invalid ) {
      this.showValidationMessages = true;
      return;
    }
  

    const reader = new FileReader();

    let becomeVendorTemplate = EmailTemplate.becomeVendorTemplate;
    becomeVendorTemplate = becomeVendorTemplate.replace('{Vendor Name}', vendorDetails.name);
    becomeVendorTemplate = becomeVendorTemplate.replace('{Email}', vendorDetails.email);
    becomeVendorTemplate = becomeVendorTemplate.replace('{Company Name}', vendorDetails.companyName);
    becomeVendorTemplate = becomeVendorTemplate.replace('{Contact No}', vendorDetails.contactNo.toString());
    becomeVendorTemplate = becomeVendorTemplate.replace('{Website}', vendorDetails.website);
    becomeVendorTemplate = becomeVendorTemplate.replace('{City}', vendorDetails.city);
    becomeVendorTemplate = becomeVendorTemplate.replace('{Emirate}', vendorDetails.emirate);
    let subject = "Become A Vendor:  " + vendorDetails.companyName;
    this.emailService.sendEmail(environment.contactEmail,  subject, becomeVendorTemplate );
 
    this.router.navigate(['thankyou', {requestType: "becomevendor"}]);
  }

}
