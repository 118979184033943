<div class="container form">
    <div class="row">
        <div class="col-md-12">
            <h1 class="dialog-title ">SEARCH FOR YOUR WAREHOUSE
                <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm.value)">
                <div class="check-rates-dialog">
                    <!--Email-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Email
                        </label>
                        <input type="text" value="" id="email" formControlName="email" placeholder="yourname@example.com" class="form-control" required>
                        <div *ngIf="email!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="email!.errors!.required">
                                Email is required.
                            </div>
                            <div *ngIf="email!.errors!.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>

                    <!--Emirate-->
                    <div class="form-group">
                        <label for="emirate" class="takzin-modal-control-label required-label">
                            Select An Emirate
                        </label>
                        <select autofocus id="emirate" type="text" class="form-control modal-select" formControlName="emirate" required>
                            <option value="Select your option" disabled selected>Select your option</option>
                            <option value="Dubai">Dubai</option>
                            <option value="Umm Al Quwain" >Umm Al Quwain</option>
                            <option value="Sharjah" >Sharjah</option>
                            <option value="Abu Dhabi" disabled>Abu Dhabi (Coming Soon!)</option>
                            <option value="Ajman" disabled>Ajman (Coming Soon!)</option>
                            <option value="Fujairah" disabled>Fujairah (Coming Soon!)</option>
                            <option value="Ras Al Khaimah" disabled>Ras Al Khaimah (Coming Soon!)</option>
                        </select>
                        <div *ngIf="emirate!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div>
                                Emirate is required.
                            </div>
                        </div>
                    </div>

                    <!--Item-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Item
                        </label>
                        <div class="row" *ngFor="let itemArray of items">
                            <div class="col-md-6" *ngFor="let item of itemArray">
                                <div *ngIf="item !== 'Other'">
                                    <label>
                                        <input type="radio" value="{{item}}" id="item" formControlName="item"
                                            (change)="onItemSelectionChange(false)">
                                        {{item}}
                                    </label>
                                </div>
                                <div *ngIf="item === 'Other'">
                                    <input type="radio" value="{{item}}" id="item" formControlName="item" (change)="onItemSelectionChange(true)">
                                    <input value="{{item}}" type="text" formControlName="other" placeholder="Other" class="other-input" [attr.disabled]="enableOtherItem ? null : true">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="item!.errors!.required">
                                Item is required.
                            </div>
                        </div>
                        <div *ngIf="other!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="other!.errors!.required">
                                Other is required.
                            </div>
                        </div>
                    </div>

                    <!--Storage-->
                    <div class="form-group">
                        <label for="storage" class="takzin-modal-control-label required-label">
                            Storage
                        </label><span class="modal-subtitle">(Please select an item)</span>
                        <div *ngIf="displayAllStorage">
                            <div class="row" *ngFor="let temp of storageArray">
                                <div class="col-md-12">
                                    <label>
                                        <input id="storage" value="{{temp}}" type="checkbox" 
                                        [checked]="isChecked(temp)" 
                                        [attr.disabled]="enableOtherItem ? null : true"
                                        (change)="onCheckChange($event)">
                                        {{temp}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.value">
                            <div class="row" *ngFor="let temp of itemStorageReference[item.value]">
                                <div class="col-md-12">
                                    <label>
                                        <input id="storage" value="{{temp}}" type="checkbox" [checked]="isChecked(temp)" (change)="onCheckChange($event)">
                                        {{temp}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.selectedStorage.length < 1 && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="this.selectedStorage.length < 1">
                                Storage is required.
                            </div>
                        </div>
                    </div>

                    <!--Handling-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Handling
                        </label>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of handlingArray">
                                <label>
                                    <input type="radio" value="{{item}}" id="handling" formControlName="handling">
                                    {{item}}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="handling!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="handling!.errors!.required">
                                Handling is required.
                            </div>
                        </div>
                    </div>

                    <!--Land-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Land
                        </label>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of landArray">
                                <label>
                                        <input type="radio" value="{{item}}" id="land" formControlName="land">
                                        {{item}}
                                    </label>

                            </div>
                        </div>
                    </div>

                    <!--Value Added-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Value Added
                        </label>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of valueAddedArray">
                                <label>
                                    <input type="radio" value="{{item}}" id="valueAdded"
                                        formControlName="valueAdded">
                                    {{item}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <!--Duration-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Duration
                        </label>
                        <div class="row">
                            <div class="col-md-12">
                                <label>
                                    <input type="radio" value="Short Term (1 to 6 Months)" id="duration" 
                                    formControlName="duration"
                                    (change)="onDateChange($event)">
                                        Short Term (1 to 6 Months)
                                </label>
                            </div>
                            <div class="col-md-12">
                                <label>
                                    <input type="radio" value="Long Term (6 Months & More)" id="duration"
                                     formControlName="duration"
                                     (change)="onDateChange($event)">
                                        Long Term (6 Months & More)
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>
                                    <input type="radio" value="Custom Dates" id="duration" 
                                    formControlName="duration"
                                    (change)="onDateChange($event)">
                                          Custom Dates
                                </label>
                            </div>

                            <div class="col-md-8">
                                <fieldset [disabled]="customDatesDisabled">

                                    <input [matDatepicker]="frompicker" (focus)="frompicker.open()" placeholder="from" formControlName="from" class="modal-from-date" [min]="minDate" [max]="to.value ? to.value : ''" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                                    <mat-datepicker #frompicker></mat-datepicker>

                                    <div></div>

                                    <input [matDatepicker]="topicker" (focus)="topicker.open()" placeholder="to" formControlName="to" class="modal-to-date" [min]="from.value ? from.value : minDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                                    <mat-datepicker #topicker></mat-datepicker>
                                </fieldset>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="dialog-footer">
                    <button type="submit" class="takzin-button-primary takzin-modal-button modal-search-warehouses" id="search-rates">Search</button>
                    <button (click)="onCancelClick()" type="button" class="takzin-button-secondary takzin-modal-button">Cancel</button>
                    <a (click)="openConfirmationDialog()" class="reset-form">Reset to Default</a>
                </div>
            </form>
        </div>
        <div>
        </div>