import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AreaLocation,
  WarehouseLocation,
  Storage,
  ProductCard
} from 'src/app/models';
import { FilterSelectionModel } from 'src/app/models/filter-selection.model';
import { GetQuoteUserInfoModel } from 'src/app/models/get-quotes-user-info.modal';
import { EmailService, WarehouseService, QuoteEnquiryService, SEOService, TransportService } from 'src/app/services';
import { EmailTemplate } from 'src/assets/templates/email.template';
import { GetQuoteModalComponent } from '../get-quote-modal/get-quote-modal.component';
import { GetRatesFilterModalComponent } from '../get-rates-filter-modal/get-rates-filter-modal.component';
import { environment } from 'src/environments/environment';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { QuotesEnquiryDto, QuotesEnquiryDtoForFirebase, StorageDataDto, StorageSearchDto } from "src/app/dtos";
import * as moment from 'moment';
import { UUID } from 'angular2-uuid';

@Component({
  selector: "takzin-warehouselisting",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"]
})


export class ProductsComponent implements OnInit {
  isMedical: boolean;
  filterSelectionModel: FilterSelectionModel;
  warehouseDetails: WarehouseLocation;
  areaLocations: AreaLocation[];
  displayOtherWarning: boolean;
  bulkStorageTypes: string[] = [
    'Bulk Ambient (20°C to 24°C)',
    'Bulk Dry (26°C & Above)',
    'Bulk Freezer (- 17°C to - 20°C)',
    'Bulk Chiller (1°C to 5°C)',
  ]
  productCardList: ProductCard[] = [];
  isLoading: boolean = true;
  displayRate: string;
  vehicleTypes = [
    { type: 'OneTonPickUp', value: '1 ton' },
    { type: 'ThreeTonPickUp', value: '3 ton' },
    { type: 'SevenTonPickUp', value: '7 ton' },
    { type: 'TenTonPickUp', value: '10 ton' },
    { type: 'ContainerFlatBedForty', value: 'Container Flat Bed 40"' },
    { type: 'CurtainTrailerForty', value: 'Curtain Trailer 40"' },
    { type: 'FlatBedTrailerForty', value: 'FlatBed Trailer 40"' },
  ];

  sourceLocations = [
    'Dubai',
    'Ajman',
    'Sharjah',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
    'Abu Dhabi',
    'Al Ain'
  ];

  constructor(private readonly warehouseService: WarehouseService,
    public dialog: MatDialog,
    private router: Router,
    private emailService: EmailService,
    private quoteEnquiryService: QuoteEnquiryService,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly seoService: SEOService
  ) { 
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.filterSelectionModel = JSON.parse(localStorage.getItem('selection')) as FilterSelectionModel;
      if(this.filterSelectionModel)
      {
        this.sentSearchDataToFireStore(this.filterSelectionModel);
      }
      
      this.displayOtherWarning = this.filterSelectionModel.item === 'Other';
      this.warehouseService.getFilteredWareHouseDetails(this.filterSelectionModel)
        .subscribe((data: WarehouseLocation) => {
          if (data) {
            this.isLoading = false;
            this.warehouseDetails = data;
            this.areaLocations = data.locations;
            this.constructProductCardList();
          }else{
            this.productCardList = [];
            this.isLoading = false;
          }
        }
      );
    }

    const pageTitle = 'takzin | Warehouse Storage Listing in UAE';
    this.seoService.updateTitle(pageTitle);

    const pageDescription = 'Warehouse Storage Listing in UAE. Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs.';
    this.seoService.updateDescription(pageDescription);
  }
  openModifySearchDialog(): void {
    const dialogRef = this.dialog.open(GetRatesFilterModalComponent, { panelClass: 'takzin-modal' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('selection', JSON.stringify(result));
          this.filterSelectionModel = JSON.parse(localStorage.getItem('selection')) as FilterSelectionModel;
        }
        this.displayOtherWarning = this.filterSelectionModel.item === 'Other';
        if(this.filterSelectionModel)
        {
          this.sentSearchDataToFireStore(this.filterSelectionModel);
        }
        this.warehouseService.getFilteredWareHouseDetails(this.filterSelectionModel)
          .subscribe((data: WarehouseLocation) => {
            if (data) {
              this.warehouseDetails = data;
              this.areaLocations = data.locations;
              this.constructProductCardList();
              this.isLoading = false;
            }
            else{
              this.productCardList = [];
              this.isLoading = false;
            }
          });
      }
    });
    this.seoService.updateTitle(environment.takzinTitle);
  }

  openGetQuoteDialog(productCard: ProductCard) {
    console.log('Akhil');
    const dialogRef = this.dialog.open(GetQuoteModalComponent, {
      panelClass: 'takzin-modal', data: {
        selectedData: productCard,
        email: this.filterSelectionModel.email
      }
    });
    dialogRef.afterClosed().subscribe((result: GetQuoteUserInfoModel) => {
      if (result) {
        let quote = 'tz-' + this.getQuoteId().toString();
        result.subject = '[' + quote + '] ' + result.subject;
  
        let customerTemplate = this.getCustomerEmail(result, productCard, quote);
        this.emailService.sendEmail(result.email, result.subject, customerTemplate );

        let salesTemplate = this.getSalesEmail(result, productCard, quote);
        
        if(result.attachment) {
          this.emailService.sendEmailWithAttachment(environment.salesEmail, result.subject, salesTemplate, result.attachment, result.fileName);
        }
        else {
          this.emailService.sendEmail(environment.salesEmail, result.subject, salesTemplate);
        }

        if(result?.companyName === 'takzin akhil')
        {
          this.router.navigate(['thankyou']);
          return;
        }

        let quoteEnquiryDtoForFirebase = this.createQuoteEnquiryDtoForFirebase(quote, productCard, result);
        this.quoteEnquiryService.createQuoteEnquiryForFirebase(quoteEnquiryDtoForFirebase);
        
        this.router.navigate(['thankyou']);
      }
    });
  }

  //Private Methods

  private sentSearchDataToFireStore(filterSelectionModel: FilterSelectionModel)
  {
    const utcDate = moment.utc();
    let storageSearchDto: StorageSearchDto = {
      id: filterSelectionModel.email,
      email: filterSelectionModel.email,
      lastSearchDate: utcDate.toDate(),
      deleteFlag: false
    };
    let storageDataDto: StorageDataDto = {
      emirate: filterSelectionModel.emirate,
      item: filterSelectionModel.item,
      other: filterSelectionModel.other,
      storage: filterSelectionModel.storage,
      handling: filterSelectionModel.handling,
      land: filterSelectionModel.land,
      valueAdded: filterSelectionModel.valueAdded,
      duration: filterSelectionModel.duration,
      from: filterSelectionModel.from ? new Date(filterSelectionModel.from) : null,
      to: filterSelectionModel.to ? new Date(filterSelectionModel.to) : null,
      dateCreated: utcDate.toDate(),
      id: UUID.UUID(),
      deleteFlag: false
    };
    this.quoteEnquiryService.createStorageSearch(storageSearchDto);
    this.quoteEnquiryService.addStorageSearchData(storageSearchDto, storageDataDto);
  }

  private getCustomerEmail(userInfo: GetQuoteUserInfoModel, productCard: ProductCard, quoteId: string): string {
    let customerTemplate = EmailTemplate.customerTemplate;
    customerTemplate = customerTemplate.replace('{Customer Name}', userInfo.name);
    customerTemplate = customerTemplate.replace('{Quote Id}', quoteId);
    customerTemplate = customerTemplate.replace('{Data}',
      this.getDataForEmail(productCard));
    return customerTemplate;
  }

  private getSalesEmail(userInfo: GetQuoteUserInfoModel, productCard: ProductCard, quoteId: string): string {
    let salesTemplate = EmailTemplate.salesTemplate;
    salesTemplate = salesTemplate.replace('{Customer Name}', userInfo.name);
    salesTemplate = salesTemplate.replace('{Email}', userInfo.email);
    salesTemplate = salesTemplate.replace('{Company Name}', userInfo.companyName);
    salesTemplate = salesTemplate.replace('{Contact No}', userInfo.contactNo.toString());
    salesTemplate = salesTemplate.replace('{Message}', userInfo.message);
    salesTemplate = salesTemplate.replace('{Quote Id}', quoteId);
    salesTemplate = salesTemplate.replace('{Data}',
      this.getDataForEmail(productCard));
    return salesTemplate;
  }

  private getDataForEmail(productCard: ProductCard): string {
    let emailData = EmailTemplate.commonData;
    emailData = emailData.replace('{Emirate}', productCard.emirate);
    emailData = emailData.replace('{Area/Region}', productCard.area);
    emailData = emailData.replace('{ItemType}', productCard.item);
    emailData = emailData.replace('{Storage Type}', productCard.storage);
    emailData = emailData.replace('{Price Range}', productCard.rangeValue);
    emailData = emailData.replace('{Handling In}', productCard.handlingInValue);
    emailData = emailData.replace('{Handling Out}', productCard.handlingOutValue);
    if (this.filterSelectionModel.land) {
      emailData = emailData + EmailTemplate.dataNewLine;
      emailData = emailData + EmailTemplate.landData.replace('{Land}', this.filterSelectionModel.land);
    }

    if (this.filterSelectionModel.valueAdded) {
      emailData = emailData + EmailTemplate.dataNewLine;
      emailData = emailData + EmailTemplate.valueAddedData.replace('{ValueAdded}', this.filterSelectionModel.valueAdded);
    }

    if (this.filterSelectionModel.duration) {
      emailData = emailData + EmailTemplate.dataNewLine;
      emailData = emailData + EmailTemplate.durationData.replace('{Duration}', this.filterSelectionModel.duration);
    }

    if (this.filterSelectionModel.from || this.filterSelectionModel.to) {
  
      var formatedFrom : any;
      var formatedTo : any;

      if(this.filterSelectionModel.from != "") {
        formatedFrom =  formatDate(this.filterSelectionModel.from, 'dd-MM-yyyy', 'en-US');}
      else {
        formatedFrom = "";
      }

      if(this.filterSelectionModel.to != "") {
        formatedTo = formatDate(this.filterSelectionModel.to, 'dd-MM-yyyy', 'en-US')
      } else {
        formatedTo = "";
      }
      
      emailData = emailData + EmailTemplate.dataNewLine;
      emailData = emailData + EmailTemplate.durationRangeData.replace('{From}', formatedFrom ).replace('{To}',formatedTo );
    
    }

    return emailData;
  }

  private constructProductCardList() {
    this.productCardList = [];
    this.areaLocations.forEach(location => {
      let isDGCargo = location.isDGCargo && (this.filterSelectionModel.item === "DG Cargos (Class 5 - 9)" ||
                              this.filterSelectionModel.item === "DG Cargos (Class 1 - 4)");
      this.isMedical = location.isMedical && (this.filterSelectionModel.item === "Medical");
      location.storage.forEach((storage: Storage) => {

        let nonBulkStorage = storage[this.filterSelectionModel.handling.toLowerCase()];
        if(isDGCargo && storage.dgCargo)
        {
          nonBulkStorage = storage.dgCargo[this.filterSelectionModel.handling.toLowerCase()];
        }
        if(this.isMedical && storage.medical)
        {
          nonBulkStorage = storage.medical[this.filterSelectionModel.handling.toLowerCase()];
        }
        const bulkStorage = storage['sqft'];
        if ((nonBulkStorage && nonBulkStorage.length > 0) ||
          (bulkStorage && bulkStorage.length > 0)) {
          let productCard = new ProductCard();
          productCard.emirate = this.filterSelectionModel.emirate;
          productCard.area = location.area;
          productCard.storage = storage.type;
          productCard.item = this.filterSelectionModel.item === 'Other' ? 
                              this.filterSelectionModel.other : this.filterSelectionModel.item;

          if (!this.bulkStorageTypes.includes(storage.type)) {
            productCard.rangeValue = this.getFormattedValueFromArray(
              nonBulkStorage, this.filterSelectionModel.handling.toLowerCase(), "bulkStorageTypes"
            );
          }
          else {
            productCard.rangeValue = this.getFormattedValueFromArray(
              bulkStorage, 'sqft', "rangeValue"
            );
          }

          let handlingValue = location.handling[0]['per' + this.filterSelectionModel.handling.toLowerCase()];
          if(isDGCargo && storage.dgCargo)
          {
            handlingValue = storage.dgCargo.handling[0]['per' + this.filterSelectionModel.handling.toLowerCase()];
          }
          if(this.isMedical && storage.medical)
          {
            handlingValue = storage.medical.handling[0]['per' + this.filterSelectionModel.handling.toLowerCase()];
          }
          productCard.handlingInValue = this.getFormattedValueFromArray(
            handlingValue,
            this.filterSelectionModel.handling.toLowerCase(), "handlingInValue"
          );

          handlingValue = location.handling[1]['per' + this.filterSelectionModel.handling.toLowerCase()];
          if(isDGCargo && storage.dgCargo)
          {
            handlingValue = storage.dgCargo.handling[1]['per' + this.filterSelectionModel.handling.toLowerCase()]
          }
          if(this.isMedical && storage.medical)
          {
            handlingValue = storage.medical.handling[1]['per' + this.filterSelectionModel.handling.toLowerCase()]
          }
          productCard.handlingOutValue = this.getFormattedValueFromArray(
            handlingValue,
            this.filterSelectionModel.handling.toLowerCase(), "handlingOutValue"
          );

          this.productCardList.push(productCard);
        }
      })
    });
  }

  private getFormattedValueFromArray(priceArray: number[], unit: string, type: String) {
    const duration = unit.toLowerCase() === 'sqft' ? '/year' : '/day'
    const value = priceArray.length > 0 ?
      priceArray.length > 1 ?
        priceArray[0] + ' AED - ' +
        priceArray[priceArray.length - 1] + ' AED'
        :
        priceArray[0] + ' AED' : '';

    let appendValueUnit = null;

    if (type == 'handlingInValue' || type == 'handlingOutValue') {
      appendValueUnit = priceArray[0] ? ' per ' + unit : ''
    }
    else {
      appendValueUnit = priceArray[0] ? ' per ' + unit + duration : ''
    }

    return value + appendValueUnit;
  }

  private getQuoteId(): number {
    return Math.floor(Math.random() * 1000000000);
  }

  private createQuoteEnquiryDto(quoteId: string, productCard: ProductCard, userInfo: GetQuoteUserInfoModel): QuotesEnquiryDto {
    let quotesEnquiryDto = new QuotesEnquiryDto();
    quotesEnquiryDto.quoteId = quoteId;
    quotesEnquiryDto.emirate = productCard.emirate;
    quotesEnquiryDto.area = productCard.area;
    quotesEnquiryDto.item = productCard.item;
    quotesEnquiryDto.storage = productCard.storage;
    quotesEnquiryDto.priceRange = productCard.rangeValue;
    quotesEnquiryDto.handlingIn = productCard.handlingInValue;
    quotesEnquiryDto.handlingOut = productCard.handlingOutValue;
    quotesEnquiryDto.land = this.filterSelectionModel.land;
    quotesEnquiryDto.valueAdded = this.filterSelectionModel.valueAdded;
    quotesEnquiryDto.duration = this.filterSelectionModel.duration;
  
    quotesEnquiryDto.to = this.filterSelectionModel?.to ? new Date(this.filterSelectionModel.to): null;
    quotesEnquiryDto.from = this.filterSelectionModel?.from ? new Date(this.filterSelectionModel.from): null;
    quotesEnquiryDto.customerData = {
      name: userInfo.name,
      companyName: userInfo.companyName,
      contactNumber: userInfo.contactNo.toString(),
      email: userInfo.email
    }



    return quotesEnquiryDto;
  }


  private createQuoteEnquiryDtoForFirebase(quoteId: string, productCard: ProductCard, userInfo: GetQuoteUserInfoModel): QuotesEnquiryDtoForFirebase {
    let quotesEnquiryDto = new QuotesEnquiryDtoForFirebase();
    quotesEnquiryDto.quoteId = quoteId ?? null;
    quotesEnquiryDto.emirate = productCard.emirate ?? null;
    quotesEnquiryDto.area = productCard.area ?? null;
    quotesEnquiryDto.item = productCard.item ?? null;
    quotesEnquiryDto.storage = productCard.storage ?? null;
    quotesEnquiryDto.priceRange = productCard.rangeValue ?? null;
    quotesEnquiryDto.handlingIn = productCard.handlingInValue ?? null;
    quotesEnquiryDto.handlingOut = productCard.handlingOutValue ?? null;
    quotesEnquiryDto.land = this.filterSelectionModel.land ?? null;
    quotesEnquiryDto.valueAdded = this.filterSelectionModel.valueAdded ?? null;
    quotesEnquiryDto.duration = this.filterSelectionModel.duration ?? null;
  
    quotesEnquiryDto.to = this.filterSelectionModel?.to ? new Date(this.filterSelectionModel.to): null;
    quotesEnquiryDto.from = this.filterSelectionModel?.from ? new Date(this.filterSelectionModel.from): null;
    quotesEnquiryDto.name = userInfo.name ?? null;
    quotesEnquiryDto.companyName = userInfo.companyName ?? null;
    quotesEnquiryDto.contactNumber = userInfo.contactNo.toString() ?? null;
    quotesEnquiryDto.email = userInfo.email ?? null;
    quotesEnquiryDto.handling = this.filterSelectionModel.handling ?? null;
    quotesEnquiryDto.createdDate = new Date(new Date().toUTCString().substr(0, 25));
    quotesEnquiryDto.quoteType = 'storage';
    quotesEnquiryDto.userMessage = userInfo.message;
    quotesEnquiryDto.createdBy = 'website'

    return quotesEnquiryDto;
  }
}

