<div class="container form">
    <div class="row">
        <div class="col-md-12">
            <h1 mat-dialog-title class="dialog-title ">REQUEST A TRANSPORT QUOTE
                <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h1>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="sendEnquiryForm" (ngSubmit)="onSubmit(sendEnquiryForm.value)">
                <div class="send-enquiry-dialog">
                    <!--Name-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Name
                        </label>
                        <input type="text" value="{{name}}" id="name" formControlName="name" class="form-control" required>
                        <div *ngIf="name!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="name!.errors!.required">
                                Name is required.
                            </div>
                        </div>
                    </div>

                    <!--Email-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Email
                        </label>
                        <input type="text" value="" id="email" formControlName="email" placeholder="yourname@example.com" class="form-control" required>
                        <div *ngIf="email!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="email!.errors!.required">
                                Email is required.
                            </div>
                            <div *ngIf="email!.errors!.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>

                    <!--CompanyName-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Company Name
                        </label>
                        <input type="text" value="" id="companyName" formControlName="companyName" class="form-control" required>
                        <div *ngIf="companyName!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="companyName!.errors!.required">
                                Company Name is required.
                            </div>
                        </div>
                    </div>

                    <!--Contact Number-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Contact Number
                        </label>
                        <input type="text" inputmode="numeric" pattern="[0-9]*" digitOnly autocomplete="off" value="" id="contactNo" formControlName="contactNo" class="form-control">
                        <div *ngIf="contactNo!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="contactNo!.errors">
                                Contact Number can only be numbers from 0-9.
                            </div>
                        </div>
                    </div>

                    <!--Pick Up Date-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Pick Up Date
                        </label>
                        <br/>
                        <div class="modal-to-date-div">
                            <input [matDatepicker]="pickupDate" (focus)="pickupDate.open()" formControlName="pickupDate" class="modal-to-date" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="pickupDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickupDate></mat-datepicker>
                        </div>
                    </div>

                    <!--Pick Up Time-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Pick Up Time
                        </label><br />
                        <!-- <input matTimepicker #t="matTimepicker" [strict]="false" id="pickupTime" formControlName="pickupTime" mode="12h" placeholder="Please select time..." name="pickupTime" [errorStateMatcher]="customErrorStateMatcher" (invalidInput)="invalidInputHandler()"
                            readonly>
                        <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon> -->
                        <input type="time" value="" id="pickupTime" formControlName="pickupTime" class="form-control time-picker">
                        <div *ngIf="pickupTime!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="pickupTime!.errors!.required">
                                Pick Up Time is required.
                            </div>
                        </div>
                    </div>

                    <!--Subject-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Subject
                        </label>
                        <input type="text" value="" id="subject" formControlName="subject" class="form-control">
                        <div *ngIf="subject!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="subject!.errors!.required">
                                Subject is required.
                            </div>
                        </div>
                    </div>

                    <!--Message-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Message <span>(Please describe your requirement in detail)</span>
                        </label>
                        <textarea value="" id="message" formControlName="message" class="form-control" required></textarea>
                        <span class="message-length-info"><small>{{message.value.length }} of 2000 characters</small></span><br />
                        <div *ngIf="message!.invalid && showValidationMessages && message!.errors!.required" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="message!.errors!.required">
                                Message is required.
                            </div>
                        </div>
                        <div *ngIf="message!.invalid && message!.errors!.maxlength" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="message!.errors!.maxlength">
                                You have exceeded the maximum character limit of 2000 characters.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer">
                    <button type="submit" class="takzin-button-primary takzin-modal-button transport-send-request">Send Request</button>
                    <button (click)="onCancelClick()" type="button" class="takzin-button-secondary takzin-modal-button">Cancel</button>
                    <br />
                    <small>By clicking the above button,
                        you agree to our <a routerLink="/terms" target="_blank" routerLinkActive="active" alt="Terms of Service">Terms of Service</a> and have read and understood our <a routerLink="/privacy" target="_blank" routerLinkActive="active" alt="Privacy Policy">Privacy Policy.</a></small>
                </div>
            </form>
        </div>
    </div>
</div>