export * from './contact-us/contact-us.component';
export * from './footer/footer.component';
export * from './get-quote-modal/get-quote-modal.component';
export * from './get-rates-filter-modal/get-rates-filter-modal.component';
export * from './home/home.component';
export * from './listing/products.component';
export * from './privacy/privacy.component';
export * from './terms/terms.component';
export * from './thankyou/thankyou.component';
export * from './about-us/about.component'
export * from './become-vendor/become-vendor.component';
export * from './transport-listing/transport-listing.component';
export * from './error-page/error-page.component';