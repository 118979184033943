<div class="container-fluid">
    <!-- <div class="row">
        <ngb-carousel class="carousel">
            <ng-template ngbSlide>
                <div class="container-fluid home-container">
                    <div class="container">
                        <div class="row home-container-row">
                            <div class="col-md-7 home-container-text">
                                <h1 class="home-container-title">Warehousing <span class="primary-color">Marketplace</span> in the Emirates</h1>
                                <h6 class="home-container-subtitle">
                                    <p>Get Instant Free Quotes of Multiple Warehouses in the UAE.</p>
                                </h6>
                                <div class="button-container">
                                    <form [formGroup]="servicesForm" class="service-form">
                                        <mat-form-field appearance="fill">
                                            <mat-label>I'M LOOKING FOR</mat-label>
                                            <mat-select (selectionChange)="selectService($event)" formControlName="service">
                                                <mat-option *ngFor="let service of services" [value]="service">
                                                    {{service.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="container-fluid transport-home-carousel">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <h1 class="transport-home-container-title">Move It With takzin<span class="primary-color"></span></h1>
                                <h6 class="transport-home-container-subtitle">
                                    <p>Efficient, Reliable & Affordable Marketplace for Trucking and Transportation Services in the Emirates.</p>
                                </h6>
                                <div class="button-container">
                                    <form [formGroup]="servicesForm" class="service-form">
                                        <mat-form-field appearance="fill">
                                            <mat-label>I'M LOOKING FOR</mat-label>
                                            <mat-select (selectionChange)="selectService($event)" formControlName="service">
                                                <mat-option *ngFor="let service of services" [value]="service">
                                                    {{service.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div> -->

    <div class="row">
        <div class="container-fluid home-container">
            <div class="container">
                <div class="row home-container-row">
                    <div class="col-md-7 home-container-text">
                        <h1 class="home-container-title">The first-ever <span class="primary-color">Warehousing Marketplace</span> in the Emirates</h1>
                        <h6 class="home-container-subtitle">
                            <p>Get Instant Free Quotes of Multiple Warehouses in the UAE.</p>
                        </h6>
                        <div class="button-container">
                            <button class="takzin-button-primary home-button home-storage-get-started-button" (click)="openDialog()">Get Started</button>
                            <button class="takzin-button-secondary home-button home-storage-how-it-works-button" (click)="howItWorksStruc()">How to Use!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="container-fluid how-it-works-container">
            <div class="row how-it-works-header">
                <div class="col-md-12 ">
                    <h1 class="home-container-title">How it Works!</h1>
                    <h6 class="home-container-subtitle ">
                        <p>Why choose takzin.com?</p>
                    </h6>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 how-it-works-content">
                                <p class="how-it-works-content-text">We've been thoroughly screening Warehouse Storage companies before we let them into our network. <span class="primary-color"><b>And many don’t make the cut.</b></span> So you can be rest assured that they are high quality
                                    providers and you’ll only be matched to the companies that can meet <span class="primary-color"><b>ALL</b></span> of your needs. While no review process can take into account every factor to perfectly predict success
                                    or failure, our process is thorough and many vendors are not accepted. Instead we accept the companies that we believe will do a great job of Warehousing.</p>
                                <p>Here are the 3 points of screening that each vendor must pass through in order to become a vendor on our network:
                                </p>
                                <ol>
                                    <li><span class="primary-color"><b>Initial interview process</b></span> – Each Warehouse Storage company is interviewed by our experienced experts in order to determine the services provided and how well the company performs
                                        these services. A multitude of factors are considered, ranging from years in service to documentation of processes and procedures to performance metrics for clients and many others.
                                    </li>
                                    <li><span class="primary-color"><b>Independent research</b></span> – Members of our staff perform independent research on the prospective Warehouse Storage companies, accessing internet records and ratings, complaints,
                                        and customer testimonials, among others.</li>
                                    <li><span class="primary-color"><b>Ongoing reviews from companies</b></span> - Finally, we get information for all of the companies that use our services. Whenever we learn about a Warehouse Storage company’s performance,
                                        be it good or bad, we add that to our database of information and use it to make informed decisions on the eligibility of warehouses in our network.</li>
                                </ol>
                                <p class="how-it-works-content-text">We make sure that the Warehouse Storage companies in our network will do a great job for you. Now that’s peace of mind. <br/>We are currently operating in Dubai, Sharjah and Umm Al Quwain.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="container-fluid figures-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="container">
                        <div class="row figures-container-row">
                            <div class="col-md-4">
                                <div> <span class="num" data-to="42" data-time="2000">42</span><span>+</span></div>
                                <h6>Partnered Vendors</h6>
                            </div>
                            <div class="col-md-4">
                                <div> <span class="num" data-to="17" data-time="2000">17</span><span>+</span></div>
                                <h6>Average Footfall</h6>
                            </div>
                            <div class="col-md-4">
                                <div> <span class="num" data-to="15" data-time="2000">15</span><span>+</span></div>
                                <h6>Team Experience</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div #howItWorks class="container-fluid learn-more-container">
            <div class="row learn-more-header ">
                <div class="col-md-12 ">
                    <h6 class="home-container-subtitle ">
                        <p>Warehousing Redefined</p>
                    </h6>

                    <h1 class="home-container-title">How to Use!</h1>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-6 ">
                    <div class="frame-image ">&nbsp;</div>
                </div>
                <div class="col-md-6 ">
                    <div class="container learn-more-body ">
                        <div class="row ">
                            <div class="col-md-1 ">
                            </div>
                            <div class="col-md-11 ">
                                <h6 class="how-it-works-header ">
                                    Search
                                </h6>
                                <h6 class="how-it-works-desc ">
                                    Search for Multiple Warehouses as per your Business needs.
                                </h6>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-1 ">
                            </div>
                            <div class="col-md-11 ">
                                <h6 class="how-it-works-header ">
                                    Compare and Select
                                </h6>
                                <h6 class="how-it-works-desc ">
                                    Compare and Select from multiple options with competitive price range of different regions within the selected Emirate.
                                </h6>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-1 ">
                            </div>
                            <div class="col-md-11 ">
                                <h6 class="how-it-works-header ">
                                    Request
                                </h6>
                                <h6 class="how-it-works-desc ">
                                    Request a Warehousing Quote by providing detailed information of your requirement.
                                </h6>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-1 ">
                            </div>
                            <div class="col-md-11 ">
                                <h6 class="how-it-works-header ">
                                    Rent
                                </h6>
                                <h6 class="how-it-works-desc ">
                                    Visit and Rent a Warehouse from the best rates provided.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="container-fluid transport-home">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="transport-home-container-title">Move It With takzin<span class="primary-color"></span></h1>
                        <h6 class="transport-home-container-subtitle">
                            <p>Efficient, Reliable & Affordable Marketplace for Trucking and Transportation Services in the Emirates.</p>
                        </h6>
                        <div class="button-container">
                            <button class="transport-home-button takzin-button-primary home-button home-transport-get-started" (click)="transportQuoteRequest()">Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>