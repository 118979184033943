import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TransportQuotesEnquiryDto } from 'src/app/dtos/transport-quotes.dto';
import { TransportEnquiryModel, TransportFilterModel, TransportModel } from 'src/app/models';
import { EmailTemplate } from 'src/assets/templates/email.template';
import { environment } from 'src/environments/environment';
import { EmailService, SEOService, TransportService, QuoteEnquiryService } from '../../services';
import { SendEnquiryModalComponent } from '../send-enquiry-modal/send-enquiry-modal.component';

@Component({
  selector: 'takzin-transport-listing',
  templateUrl: './transport-listing.component.html',
  styleUrls: ['./transport-listing.component.scss']
})
export class TransportListingComponent implements OnInit {

  transportFilterForm: FormGroup;
  transportFilterModel: TransportFilterModel;
  showValidationMessages: boolean;
  transportData: TransportModel;
  displayVehicleType: String;
  displayRate: string;
  displayNoDataMessage: boolean;
  requestType: string;

  vehicleTypes = [
    { type: 'OneTonPickUp', value: '1 ton' },
    { type: 'ThreeTonPickUp', value: '3 ton' },
    { type: 'SevenTonPickUp', value: '7 ton' },
    { type: 'TenTonPickUp', value: '10 ton' },
    { type: 'ContainerTrailerForty', value: 'Container Flatbed 40"' },
    { type: 'CurtainTrailerForty', value: 'Curtain Trailer 40"' },
    { type: 'FlatBedTrailerForty', value: 'Flatbed Trailer 40"' },
  ];

  sourceLocations = [
    'Dubai',
    'Ajman',
    'Sharjah',
    'Umm Al Quwain',
    'Ras Al Khaimah',
    'Fujairah',
    'Abu Dhabi',
    'Al Ain'
  ];

  sourceDestinationMapping = {
    'Select your option': this.sourceLocations,
    'Dubai': this.sourceLocations,
    'Ajman': this.sourceLocations,
    'Sharjah': this.sourceLocations,
    'Umm Al Quwain': this.sourceLocations,
    'Ras Al Khaimah': this.sourceLocations,
    'Fujairah': this.sourceLocations,
    'Abu Dhabi': this.sourceLocations,
    'Al Ain': this.sourceLocations
  }

  @ViewChild('transportQuote') public transportQuote: ElementRef;
  @ViewChild('howItWorks') public howItWorks:ElementRef;

  constructor(
    private readonly transportService: TransportService,
    public dialog: MatDialog,
    private router: Router,
    private emailService: EmailService,
    private readonly seoService: SEOService,
    private readonly config: NgbCarouselConfig,
    private readonly route: ActivatedRoute,
    private readonly quoteEnquiryService: QuoteEnquiryService
  ) {
    this.requestType = this.route.snapshot.paramMap.get('requestType');
    
    config.interval =10000;  
    config.wrap = true;  
    config.keyboard = true;  
    config.pauseOnHover = false; 
    config.pauseOnFocus = false;

    this.transportFilterForm = new FormGroup({
      source: new FormControl(this.transportFilterModel?.source ?
        this.transportFilterModel?.source : 'Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ]),
      destination: new FormControl(this.transportFilterModel?.destination ?
        this.transportFilterModel?.destination : 'Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ]),
      vehicleType: new FormControl(this.transportFilterModel?.vehicleType ?
        this.transportFilterModel?.vehicleType : 'Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ])
    });
  }

  get source() {
    return this.transportFilterForm!.get('source');
  }

  get destination() {
    return this.transportFilterForm!.get('destination');
  }

  get vehicleType() {
    return this.transportFilterForm!.get('vehicleType');
  }

  ngOnInit(): void {
    const pageTitle = 'takzin | Transport in Dubai | UAE';
    this.seoService.updateTitle(pageTitle);

    const pageDescription = 'Transport in Dubai, UAE. Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Transport. takzin is helping individuals & businesses find right warehouse storage and transport for their needs.';
    this.seoService.updateDescription(pageDescription);
    
  }

  ngAfterViewInit() {
    if(this.requestType === 'quote')
    {
      setTimeout(() => {
        this.transportQuote.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start', alignToTop: true });
      }, 0)
      
    }
  }

  onSubmit(transportFilter: TransportFilterModel) {
    if (this.transportFilterForm.invalid) {
      this.showValidationMessages = true;
      return;
    }
    this.displayVehicleType = this.vehicleTypes.filter(vehicle => vehicle.type === transportFilter.vehicleType)[0].value;
    this.transportFilterModel = transportFilter;
    this.transportService.getFilteredTransportDetails(transportFilter).subscribe((data) => {
      if(data?.vehicleDetails.length > 0)
      {
        this.transportData = data;
        this.setDisplayRate(data);
        this.displayNoDataMessage = false;
      }
      else
      {
        this.transportData = null;
        this.displayNoDataMessage = true;
      }
      
    })
  }

  sendEnquiry() {
    const dialogRef = this.dialog.open(SendEnquiryModalComponent, {
      panelClass: 'takzin-modal', data: {
        selectedData: this.transportFilterModel
      }
    });
    dialogRef.afterClosed().subscribe((result: TransportEnquiryModel) => {
      if (result) {
        let quote = 'tr-' + this.getQuoteId().toString();

        let customerTemplate = this.getCustomerEmail(result, quote);
        let salesTemplate = this.getEmailForSales(result, quote);

        result.subject = '[' + quote + '] ' + result.subject;
        this.emailService.sendEmail(result.email, result.subject, customerTemplate);
        this.emailService.sendEmail(environment.salesEmail, result.subject, salesTemplate);

        let transportQuote = this.createTransportQuoteDto(quote, result);
        console.log(transportQuote);
        this.quoteEnquiryService.createTransportQuoteEnquiry(transportQuote);

        this.router.navigate(['thankyou', {requestType: "transportation"}]);
      }
    });
  }

  onSelectionChanged(type: String) {
    if (type === 'vehicleType') {
      this.source.setValue('Select your option');
      this.destination.setValue('Select your option');
      this.showValidationMessages = false;
    }
    else if (type === 'source') {
      this.destination.setValue('Select your option');
    }
    this.transportData = null;
  }

  private createTransportQuoteDto(quoteId: string, transportEnquiryModel: TransportEnquiryModel): TransportQuotesEnquiryDto{
    let transportQuote: TransportQuotesEnquiryDto = {
      quoteId: quoteId,
      vehicleType: this.transportFilterModel.vehicleType ?? null,
      name: transportEnquiryModel.name ?? null,
      email: transportEnquiryModel.email ?? null,
      companyName: transportEnquiryModel.companyName ?? null,
      contactNumber: transportEnquiryModel.contactNo.toString() ?? null,
      userMessage: transportEnquiryModel.message ?? null,
      pickupLocation: this.transportFilterModel.source ?? null,
      pickupDate: transportEnquiryModel.pickupDate ?? null,
      pickupTime: transportEnquiryModel.pickupTime ?? null,
      dropLocation: this.transportFilterModel.destination ?? null,
      createdDate: new Date(new Date().toUTCString().substr(0, 25)),
      quoteType: 'transport',
      createdBy: 'website'
    }

    return transportQuote;
  }

  private setDisplayRate(data: TransportModel) {
    let rates = data?.vehicleDetails[0]?.rates;
    this.displayRate = '';
    if (rates.length > 1) {
      this.displayRate = 'AED ' + rates[0] + ' to AED ' + rates[rates.length - 1];
    }
    else {
      this.displayRate = 'AED ' + rates[0];
    }
  }

  private getQuoteId(): number {
    return Math.floor(Math.random() * 1000000000);
  }

  private getCustomerEmail(transferEnquiryModel: TransportEnquiryModel, quoteId: string): string {
    let customerTemplate = EmailTemplate.customerTransportTemplate;
    customerTemplate = customerTemplate.replace('{Customer Name}', transferEnquiryModel.name);
    customerTemplate = customerTemplate.replace('{Quote Id}', quoteId);
    customerTemplate = customerTemplate.replace('{Data}',
      this.getDataForEmailForCustomer(transferEnquiryModel));
    return customerTemplate;
  }

  private getEmailForSales(transferEnquiryModel: TransportEnquiryModel, quoteId: string): string {
    let customerTemplate = EmailTemplate.salesTemplate;
    customerTemplate = customerTemplate.replace('{Customer Name}', transferEnquiryModel.name);
    customerTemplate = customerTemplate.replace('{Quote Id}', quoteId);
    customerTemplate = customerTemplate.replace('{Data}',
      this.getDataForEmailForSales(transferEnquiryModel));
    customerTemplate = customerTemplate.replace('{Email}', transferEnquiryModel.email);
    customerTemplate = customerTemplate.replace('{Company Name}', transferEnquiryModel.companyName);
    customerTemplate = customerTemplate.replace('{Contact No}', transferEnquiryModel.contactNo.toString());
    customerTemplate = customerTemplate.replace('{Message}', transferEnquiryModel.message);
    return customerTemplate;
  }

  private getDataForEmailForSales(transferEnquiryModel: TransportEnquiryModel) {
    let vehicleType = this.vehicleTypes.filter(val => {
      return val.type.toLowerCase() === this.vehicleType.value.toString().toLowerCase()
    })[0];
    let emailData = EmailTemplate.vehicleType.replace('{Vehicle Type}', vehicleType.value);
    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupLocation.replace('{Pickup Location}', this.source.value);
    if (transferEnquiryModel.pickupDate) {
      emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupDate.replace('{Pickup Date}',
        formatDate(transferEnquiryModel.pickupDate, 'dd-MM-yyyy', 'en-US'));
    }
    if (transferEnquiryModel.pickupTime) {
      emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupTime.replace('{Pickup Time}', transferEnquiryModel.pickupTime);
    }

    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.dropLocation.replace('{Drop Location}', this.destination.value);
    return emailData;
  }

  private getDataForEmailForCustomer(transferEnquiryModel: TransportEnquiryModel) {
    let vehicleType = this.vehicleTypes.filter(val => {
      return val.type.toLowerCase() === this.vehicleType.value.toString().toLowerCase()
    })[0];
    let emailData = EmailTemplate.companyName.replace('{Company Name}', transferEnquiryModel.companyName);
    if (transferEnquiryModel.contactNo) {
      emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.contactNo.replace('{Contact No}', transferEnquiryModel.contactNo.toString());
    }

    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.vehicleType.replace('{Vehicle Type}', vehicleType.value);
    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupLocation.replace('{Pickup Location}', this.source.value);
    if (transferEnquiryModel.pickupDate) {
      emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupDate.replace('{Pickup Date}',
        formatDate(transferEnquiryModel.pickupDate, 'dd-MM-yyyy', 'en-US'));
    }
    if (transferEnquiryModel.pickupTime) {
      emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.pickupTime.replace('{Pickup Time}', transferEnquiryModel.pickupTime);
    }

    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.dropLocation.replace('{Drop Location}', this.destination.value);
    emailData = emailData + EmailTemplate.dataNewLine + EmailTemplate.priceRange.replace('{Price Range}', this.displayRate);
    return emailData;
  }

  public transportQuoteRequest(): void {
    this.transportQuote.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start', alignToTop: false });
  }

  public howItWorksStruc():void {
    this.howItWorks.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
}

}
