import { Component, ModuleWithComponentFactories } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ConfirmBoxComponent } from 'src/app/components/shared/confirm-box/confirm-box.component';
import { FilterSelectionModel } from 'src/app/models/filter-selection.model';
import { ElementRef } from '@angular/core';
import { StorageDataDto, StorageSearchDto } from 'src/app/dtos';
import { QuoteEnquiryService } from 'src/app/services';

@Component({
  selector: 'takzin-get-rates-filter-modal',
  templateUrl: './get-rates-filter-modal.component.html',
  styleUrls: ['./get-rates-filter-modal.component.scss']
})
export class GetRatesFilterModalComponent {

  filterForm: FormGroup;
  filterSelectionModel: FilterSelectionModel;
  selectedStorage: string[];
  showValidationMessages: boolean = false;
  enableOtherItem: boolean = false;
  isSqftEnabled: boolean = false;
  displayAllStorage: boolean = true;
  minDate: Date = new Date();
  disabled: boolean = true;
  customDatesDisabled: boolean =true;

  itemsArray: string[] = [
    'FMCG (Food)',
    'FMCG (Non - Food)',
    'Electronics',
    'White Goods',
    'Garments/Fashion',
    'Furniture',
    'DG Cargos (Class 1 - 4)',
    'DG Cargos (Class 5 - 9)',
    'Medical',
    'Other'
  ];

  items: string[][] = [];

  storageArray: string[] = [
    'Ambient (20°C to 24°C)',
    'Dry (26°C & Above)',
    'Freezer (- 17°C to - 20°C)',
    'Chiller (1°C to 5°C)',
    'Bulk Ambient (20°C to 24°C)',
    'Bulk Dry (26°C & Above)',
    'Bulk Freezer (- 17°C to - 20°C)',
    'Bulk Chiller (1°C to 5°C)',
    'Open Yard/Space',
  ];

  itemStorageReference= {
    'Electronics': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
    ],
    'White Goods': [
      'Dry (26°C & Above)',
      'Bulk Dry (26°C & Above)',
    ],
    'Garments/Fashion': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
    ],
    'Furniture': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
    ],
    'FMCG (Food)': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Freezer (- 17°C to - 20°C)',
      'Chiller (1°C to 5°C)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
      'Bulk Freezer (- 17°C to - 20°C)',
      'Bulk Chiller (1°C to 5°C)',
    ],
    'FMCG (Non - Food)': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
    ],
    'DG Cargos (Class 1 - 4)': [
      'Ambient (20°C to 24°C)',
      'Bulk Ambient (20°C to 24°C)',
    ],
    'DG Cargos (Class 5 - 9)': [
      'Ambient (20°C to 24°C)',
      'Bulk Ambient (20°C to 24°C)',
    ],
    'Medical': [
      'Ambient (20°C to 24°C)'
    ],
    'Other': [
      'Ambient (20°C to 24°C)',
      'Dry (26°C & Above)',
      'Freezer (- 17°C to - 20°C)',
      'Chiller (1°C to 5°C)',
      'Bulk Ambient (20°C to 24°C)',
      'Bulk Dry (26°C & Above)',
      'Bulk Freezer (- 17°C to - 20°C)',
      'Bulk Chiller (1°C to 5°C)',
      'Open Yard/Space',
    ]
  }

  handlingArray: string[] = [
    'Pallet',
    'CBM',
  ];

  landArray: string[] = [
    'Main Land',
    'Freezone',
  ];

  valueAddedArray: string[] = [
    'Packing',
    'Unpacking',
  ];

  get emirate() {
    return this.filterForm!.get('emirate');
  }

  get item() {
    return this.filterForm!.get('item');
  }

  get other() {
    return this.filterForm!.get('other');
  }

  get handling() {
    return this.filterForm!.get('handling');
  }

  get valueAdded() {
    return this.filterForm!.get('valueAdded');
  }

  get from() {
    return this.filterForm!.get('from');
  }

  get to() {
    return this.filterForm!.get('to');
  }

  get email() {
    return this.filterForm!.get('email');
  }



  constructor(
    private readonly dialogRef: MatDialogRef<GetRatesFilterModalComponent>,
    private readonly dialog: MatDialog,
    private readonly el: ElementRef,
    private readonly quoteEnquiryService: QuoteEnquiryService
  ) {
    
    this.filterSelectionModel = JSON.parse(localStorage.getItem('selection')) as FilterSelectionModel;
    if(this.filterSelectionModel){
      this.displayAllStorage = false;
      if(this.filterSelectionModel.item == 'Other') {
        this.enableOtherItem = true;
      }

      if(this.filterSelectionModel.duration ==="Custom Dates") {
        this.customDatesDisabled = false;
      }
    }
    this.selectedStorage = this.filterSelectionModel ? this.filterSelectionModel.storage : [];
    this.filterForm = new FormGroup({
      emirate: new FormControl(this.filterSelectionModel?.emirate ? 
        this.filterSelectionModel?.emirate : 'Select your option', [
        Validators.required,
        Validators.pattern('^((?!Select your option).)*$'),
      ]),
      item: new FormControl(this.filterSelectionModel?.item, []),
      other: new FormControl(this.filterSelectionModel?.other, []),
      handling: new FormControl(this.filterSelectionModel?.handling, [
        Validators.required,
      ]),
      land: new FormControl(this.filterSelectionModel?.land, []),
      valueAdded: new FormControl(this.filterSelectionModel?.valueAdded, []),
      duration: new FormControl(this.filterSelectionModel?.duration, []),
      from: new FormControl(this.filterSelectionModel?.from, []),
      to: new FormControl(this.filterSelectionModel?.to, []),
      email: new FormControl(this.filterSelectionModel?.email, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
    });

    if(this.enableOtherItem)
    {
      this.item.setValidators([]);
      this.other.setValidators([Validators.required]);
    }
    else{
      this.other.setValidators([]);
      this.item.setValidators([Validators.required]);
    }

    this.items = this.convertTo2DList(this.itemsArray, 2);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(selectedData: FilterSelectionModel) {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

    if (invalidControl) {
      invalidControl.focus();
    }

    if (this.filterForm.invalid || this.selectedStorage.length == 0) {
      this.showValidationMessages = true;
      return;
    }
    selectedData.storage = this.selectedStorage;
    this.dialogRef.close(selectedData);
  }

  convertTo2DList(list: string[], index: number) {
    const newArr = [];
    while (list.length) newArr.push(list.splice(0, index));
    return newArr;
  }

  onItemSelectionChange(status: boolean)
  {
    if(status)
    {
      this.item.clearValidators();
      this.other.setValidators([Validators.required]);
    }
    else{
      this.other.clearValidators();
      this.item.setValidators([Validators.required]);
    }
    this.item.updateValueAndValidity();
    this.other.updateValueAndValidity();
    this.setOtherItemStatus(status);
    this.selectedStorage = [];
    this.displayAllStorage = false;
  }

  setOtherItemStatus(status: boolean) {
    this.enableOtherItem = status;
    if(!status)
    {
      this.other.setValue('');
    }
  }

  resetToDefault() {
    this.showValidationMessages = false;
    this.filterSelectionModel = null;
    this.filterForm.reset();
    this.emirate.setValue('Select your option');
    this.displayAllStorage = true;
    this.selectedStorage = [];
  }

  openConfirmationDialog() {
    if(!this.filterForm.dirty && this.selectedStorage.length == 0)
    {
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.resetToDefault();
      }
    });
  }

  isChecked(type: string) {
    if (this.selectedStorage.includes(type)) {
      return true;
    }
    return false;
  }

  onCheckChange(event) {
    if (event.target.checked) {
      this.selectedStorage.push(event.target.value);
    }
    else {
      this.selectedStorage = this.selectedStorage.filter(obj => obj !== event.target.value);
    }
  }

  isSqftVisible()
  {
    if(this.selectedStorage.includes('Bulk Ambient (20°C to 24°C)') || 
    this.selectedStorage.includes('Bulk Dry (26°C & Above)'))
    {
      return true;
    }
    return false;
  }

  onDateChange(event) {
    const value = event.target.value; 
    if(value == "Custom Dates") {
      this.customDatesDisabled = false;
    }
    else{
      this.customDatesDisabled = true;
      this.from.setValue("");
      this.to.setValue("");
    }

  }
  

}
