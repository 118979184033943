export class EmailTemplate {
    static baseTemplate = '<div style="background-color: #F8F9FA; padding-top: 24px; padding-bottom: 24px; padding-left: 1%; padding-right: 1%; font-family: Arial;"><div style="background-color:#FFFFFF; max-width:668px; margin: auto; padding-top:32px; padding-bottom:32px; padding-left:32px; padding-right:32px;"><div style="padding-bottom:24px;font-size: 2.250rem;font-weight: 400;">taḵzīn</div><div style="color:#000000; font-size:16px; line-height:22px;"> {body} </div><div style="color: #000000; font-size: 16px; line-height: 22px">    --</div><div style="color: #747474; font-size: 12px; line-height: 18px; margin-top: 24px;">    taḵzīn    <br />    www.takzin.com    <br />    P: +971 56 783 1004/+971 50 237 3759    <br />    E: contact@takzin.com    </div><div style="color:#747474; font-size:12px; line-height:18px;">--</div><br /><div style="color: #747474; font-size: 10px; line-height: 14px; margin-top:10px;">©2020 | takzin.com | All Rights Reserved.</div></div></div>';

    static customerTemplate = 'Dear {Customer Name},<br /><br />Thank You for choosing taḵzīn <br /><br />Your Quote_id: {Quote Id} <br /><small>(Please note down your quote_id for all the future communications related to this enquiry)</small><br /><br />We received your email request for the following requirement:<br /><br /><div style="border: solid;width: fit-content;padding: 10px;border-style: groove;">{Data}</div><br />Your message has been received.<br /><br />We are currently in the process of reviewing your request to find the best warehouse space quotes that match your requirement.<br />You can expect a price quote from us within 24 hours.<br /><br />Our Business Hours are Saturday through Thursday 8 AM to 6 PM.<br /><br />If you have any additional information that you think will help us to assist you, please feel free to call us or send us an email at contact@takzin.com mentioning your Quote_id.<br /><br />We look forward to making this your best warehousing experience ever!<br /><br />Best Regards,<br />Team taḵzīn';

    static salesTemplate = 'Dear Sales,<br /><br />Please find the below request for requirement: <br /><br />Quote_id: {Quote Id} <br /><small>(Please note down quote_id for all the future communications related to this enquiry)</small><br /><br /><b>Name:</b> {Customer Name}<br /><br /><b>Email:</b> {Email}<br /><br /><b>Company Name:</b> {Company Name}<br /><br /><b>Contact No:</b> {Contact No}<br /><br /><b>Message:</b> {Message}<br /><br /><div style="border: solid;width: fit-content;padding: 10px;border-style: groove;">{Data}</div><br />Best Regards,<br />Team takzin'

    static contactTemplate = 'Dear Sales,<br /><br />Please find the below information to connect with the Customer: <br /><br /><b>Name:</b> {Customer Name}<br /><br /><b>Email:</b> {Email}<br /><br /><b>Company Name:</b> {Company Name}<br /><br /><b>Contact No:</b> {Contact No}<br /><br /><b>Message:</b> {Message}<br /><br />Best Regards,<br />Team takzin'

    static becomeVendorTemplate = 'Dear Sales,<br /><br />Please find the below information to connect with the Vendor: <br /><br /><b>Company Name:</b> {Company Name}<br /><br /><b>Name:</b> {Vendor Name}<br /><br /><b>Email:</b> {Email}<br /><br /><b>Website:</b> {Website}<br /><br /><b>Contact No:</b> {Contact No}<br /><br /><b>Emirate:</b> {Emirate}<br /><br /><b>Region/Area:</b> {City}<br /><br />Best Regards,<br />Team takzin'

    static commonData = '<b>Emirate:</b> {Emirate}<br /><br /><b>Area/Region:</b> {Area/Region}<br /><br /><b>Item Type:</b> {ItemType}<br /><br /><b>Storage Type:</b> {Storage Type}<br /><br /><b>Price Range:</b> {Price Range}<br /><br /><b>Handling In:</b> {Handling In}<br /><br /><b>Handling Out:</b> {Handling Out}';

    static dataNewLine = '<br /><br />';

    static landData = '<b>Land:</b> {Land}'

    static valueAddedData = '<b>ValueAdded:</b> {ValueAdded}'

    static durationData = '<b>Duration:</b> {Duration}'

    static durationRangeData = '<b>From:</b> {From} - <b>To:</b> {To}'

    static customerName = '<b>Name:</b> {Customer Name}'
    static email = '<b>Email:</b> {Email}'
    static companyName = '<b>Company Name:</b> {Company Name}'
    static contactNo = '<b>Contact No.:</b> {Contact No}'
    static pickupLocation = '<b>Pick Up Location:</b> {Pickup Location}'
    static pickupDate = '<b>Pick Up Date:</b> {Pickup Date}'
    static pickupTime = '<b>Pick Up Time:</b> {Pickup Time}'
    static dropLocation = '<b>Drop Location:</b> {Drop Location}'
    static subject = '<b>Subject:</b> {Subject}'
    static message = '<b>Message:</b> {Message}'
    static vehicleType = '<b>Vehicle Type:</b> {Vehicle Type}'
    static priceRange = '<b>Price Range:</b> {Price Range}'

    static customerTransportTemplate = 'Dear {Customer Name},<br /><br />Thank You for choosing taḵzīn <br /><br />Your Quote_id: {Quote Id} <br /><small>(Please note down your quote_id for all the future communications related to this enquiry)</small><br /><br />We received your email request for the following requirement:<br /><br /><div style="border: solid;width: fit-content;padding: 10px;border-style: groove;">{Data}</div><br />Your message has been received.<br /><br />We are currently in the process of reviewing your request to find the best transportation quotes that match your requirement.<br />You can expect a price quote from us within 24 hours.<br /><br />Our Business Hours are Saturday through Thursday 8 AM to 6 PM.<br /><br />If you have any additional information that you think will help us to assist you, please feel free to call us or send us an email at contact@takzin.com mentioning your Quote_id.<br /><br />We look forward to making this your best transportation experience ever!<br /><br />Best Regards,<br />Team taḵzīn';
}