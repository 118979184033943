<div class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 about-us-header">
                <h1>About Us</h1>
                <div class="border"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 about">
                <p>
                    Our Founders realized that there is a dire need in the warehousing industry in the United Arab Emirates (UAE) for a platform where companies and individual businesses can find a warehouse storage and warehousing services stress free and Fast. Traditionally,
                    to find a warehouse, storage and services consume a lot of time and manual effort to even get a quote as per the business need in 24 hours. We represent a wide variety of warehouses and our company can help you find a warehouse space
                    nearly anywhere in the Emirates.
                </p>
                <p>
                    At takzin.com, we focus on what we know best. Our team brings years of collective experience in these industries, so that we can help your company best identify the most efficient and cost effective strategies.
                </p>
                <p>
                    Most importantly, takzin.com is not a vendor itself. Rather, we are in the business of providing your organization with Instant, multiple quotes or discounted pricing of qualified vendors. You choose the best price and terms! takzin.com truly provides
                    a win-win situation for your outsourced business services needs.
                </p>
                <p>
                    Simply fill out our quote request form and we will provide you with the best free quotes of multiple warehouses of that region which best fits your business needs. We will process your request and you will be contacted via telephone or email with a quote.
                    At takzin.com, our mission is to help companies find a quality outsourced vendor in virtually any situation!
                </p>
                <p>
                    If you would like to learn more about the benefits of working with takzin.com, please contact us today.
                </p>
            </div>
            <div class="col-md-12">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <div class="slogan">
                                <h1 class="title">Our Mission</h1>
                                <p>
                                    Delivering a superior and modern warehousing experience by providing our clients with unparalleled customer service, advanced technology and two decades of industry knowledge and expertise.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <div class="slogan">
                                <h1 class="title">Our Vision</h1>
                                <p>We see customers stress-free and successfully moving their merchandise across the Emirates. One company. One contact. One seamless Storage and Warehousing solution.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <div class="slogan">
                                <h1 class="title">Our Promise to You</h1>
                                <p>Our team is committed to providing you with a superior personal customer service experience to ensure your Storage and Warehousing needs are fulfilled beyond your expectation.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>