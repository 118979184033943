import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TransportFilterModel, TransportModel } from "../models";

@Injectable()
export class TransportService{
    url = environment.transportData;

    constructor(
        private http: HttpClient
    ){}

    getTransportData() {
        return this.http.get<TransportModel[]>(this.url);
    }

    getFilteredTransportDetails(filter: TransportFilterModel) {
        return this.getTransportData().pipe(
            map((data) => {
                var responseData = data.filter(transport => transport.source.toLowerCase() == filter.source.toLowerCase())[0];
                if(responseData)
                {
                    responseData.vehicleDetails = responseData?.vehicleDetails.filter(vehicleDetail => {
                        return vehicleDetail.destination.toLowerCase() == filter.destination.toLowerCase() &&
                        vehicleDetail.vehicleType.toLowerCase() == filter.vehicleType.toLowerCase()
                    });
                    responseData?.vehicleDetails.forEach((detail) => {
                        detail.rates = detail.rates?.sort((one, two) => (one < two ? -1 : 1));
                    })
                }
                return responseData;
            })
        );
    }
}