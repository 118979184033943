import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { QuotesEnquiryDto, QuotesEnquiryDtoForFirebase, StorageDataDto, StorageSearchDto } from "../dtos";
import { TransportQuotesEnquiryDto } from "../dtos/transport-quotes.dto";
import { UUID } from 'angular2-uuid';

@Injectable()
export class QuoteEnquiryService {
    quotesApiBaseUrl: string;

    constructor(
        private _http: HttpClient,
        private db: AngularFirestore
    ) {
        this.quotesApiBaseUrl = environment.quotesApiBaseUrl;
    }

    createQuoteEnquiryForFirebase(quotesEnquiryDto: QuotesEnquiryDtoForFirebase) {
        try{
        quotesEnquiryDto.id = UUID.UUID();
        quotesEnquiryDto.status = 'new';
        this.db.collection('quotes').doc(quotesEnquiryDto.id).set(Object.assign({}, quotesEnquiryDto))
        }
        catch{
            console.log('error')
        }
    }

    createTransportQuoteEnquiry(quotesEnquiryDto: TransportQuotesEnquiryDto) {
        try{
            quotesEnquiryDto.id = UUID.UUID();
            quotesEnquiryDto.status = 'new';
            this.db.collection('quotes').doc(quotesEnquiryDto.id).set(Object.assign({}, quotesEnquiryDto))
        }
        catch{
            console.log('error')
        }
        
    }

    createStorageSearch(storageSearchDto: StorageSearchDto)
    {
        this.db.collection('storageSearches').doc(storageSearchDto.id).set(Object.assign({}, storageSearchDto))
    }

    addStorageSearchData(storageSearchDto: StorageSearchDto, storageDataDto: StorageDataDto)
    {
        this.db.collection(`storageSearches/${storageSearchDto.id}/searchData`).doc(storageDataDto.id).set(Object.assign({}, storageDataDto))
    }

    createQuoteEnquiry(quotesEnquiryDto: QuotesEnquiryDto) {
        
        let url = this.quotesApiBaseUrl + '/QuoteEnquiry';
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this._http.post(url, quotesEnquiryDto, httpOptions)
            .toPromise()
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }
}