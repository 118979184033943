export class QuotesEnquiryDto{
    quoteId: string;
    emirate: string;
    area: string;
    item: string;
    storage: string;
    priceRange: string;
    handlingIn: string;
    handlingOut: string;
    land: string;
    valueAdded: string;
    duration: string;
    to: Date;
    from: Date;
    customerData: CustomerData;
}

export class QuotesEnquiryDtoForFirebase{
    id?: string;
    quoteId: string;
    emirate: string;
    area: string;
    item: string;
    storage: string;
    priceRange: string;
    handlingIn: string;
    handlingOut: string;
    land: string;
    valueAdded: string;
    duration: string;
    to: Date;
    from: Date;
    name: string;
    email: string;
    companyName: string;
    contactNumber: string;
    userMessage: string;
    handling: string;
    createdDate: Date;
    quoteType: string;
    status?: string;
    createdBy: string;
}

export interface CustomerData{
    name: string;
    email: string;
    companyName: string;
    contactNumber: string;
}