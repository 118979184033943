<div class="container">
    <h1 dir="ltr" style="line-height:1.38;margin-top:20pt;margin-bottom:0pt;"><b>READ OUR PRIVACY POLICY</b></h1> 
    <p dir="ltr" style="line-height:1.38;margin-top:20pt;margin-bottom:0pt;"><u><b>WHAT WE LOOK FOR</b></u></p>
    <p>
    takzin.com ("us", "we", “our”) takes the protection of your online privacy seriously and this Privacy Policy sets out how we use and protect any personal information we obtain from dealings which arise from you using this website. takzin.com is not responsible for the privacy practices of any websites which may be linked from the takzin.com website, so please check these separately.
    The Website may use non-personal “aggregated data” to enhance the operation of our website, or analyze interest in the areas of our website. Additionally, if you provide takzin.com with content for publishing or feedback, we may publish your user name or other identifying data.
    We may change this Privacy Policy from time to time by updating this page so please check our Privacy Policy for any changes when you revisit our website.
    </p>

    <p><u><b>WHAT PERSONAL INFORMATION DO WE COLLECT?</b></u></p>

<p>
    We ask for your personal information for instances including but not limited to making an enquiry for the quote, purchase, register, sign up for promotions, offers or competitions, or to receive news submitting user-generated reviews or ratings, engaging in any social media functions on our services. Depending on which of these services you use, even if you do not complete your enquiry/purchase, we may collect:
</p>

<p><u><b>Information you give us:</b></u></p>

<ul>
    <li>Personal identification information such as, your Name;</li>
    <li>Contact information such as Email address, Contact number and Company’s Name;</li>
    <li>Demographic information: such as, postal address;</li>
    <li>Financial information: such as, credit/debit card numbers;</li>
    <li>Your Enquiry and Booking history;</li>
    <li>If you have completed a survey or entered a competition with us;</li>
    <li>If you contact us by phone, email or otherwise, we will keep a record of that correspondence; and your marketing preferences, including any consents you have given us when you subscribe to our newsletter or set up an online account.</li>
    <li>
        Information you provide if you report a problem with our website
    </li>
</ul>

<p><u><b>Information we collect automatically:</b></u></p>

<ul>
    <li>Information related to the browser, device or operating system you use to access our Services;</li>
    <li>Your IP address, the website you came from, information on actions taken on our Services including but not limited to pages viewed, dated and times of visits, time spent on each page, products viewed, clicked on, enquired and booked;</li>
</ul>

<p><u><b>Information we receive from third parties:</b></u></p>

<p>Sometimes we receive personal data about you from third parties when you engage with our services through social media, or other non-takzin.com sites or applications, those sites will share personal data with us including, but not limited to:</p>

<ul>
    <li>The content you have viewed or interacted with;</li>
    <li>About adverts within the content which you have been shown or clicked on;</li>
    <li>Your IP address, registered beacons or GPS (geo location) signals you have received;</li>
    <li>Publicly available information; and</li>
    <li>The content you have viewed or interacted with;</li>
    <li>Non-personal information used to supplement existing information, such as demographics and affluence metrics (e.g. social-demographic groupings through matching postcodes).</li>
</ul>

<p>The privacy notices for these sites and applications will contain more detail about this and how to change your privacy settings on those sites and applications.</p>

<h1><b>HOW DO WE USE THIS INFORMATION, AND WHAT IS THE LEGAL BASIS FOR THIS USE?</b></h1> 

<p><u><b>We process your personal data for the following purposes:</b></u></p>

<p>To fulfill a contract, or take steps linked to a contract including fulfilling any orders or booking a warehouse at your place. This includes:</p>

<ul>
    <li>Processing your contract, registration for the Services;</li>
    <li>Providing the Services, to communicate with you about them or your account with us;</li>
    <li>Communicating with you (including by email and SMS) and providing our customer services;</li>
    <li>Verifying your identity; and sending you information about changes to our terms or policies.</li>
</ul>

<p><u><b>To conduct our business and pursue our legitimate interests, in particular:</b></u></p>

<ul>
    <li>We will use your personal data to provide Information and Services you have requested, and respond to any comments or complaints you may send us;
    </li>
    <li>We monitor use of our Services, and use your personal data to help us monitor, improve and protect our content, Services and websites, both online and offline;</li>
    <li>We use your personal data to personalize our Features and Services for you;</li>
</ul>

<p><u><b>To prevent, investigate and/or report fraud, misrepresentation, security incidents or crime, in accordance with applicable law;</b></u></p>


<ul>
    <li>We use information you provide to investigate any complaints received from you or from others, about our website or our services;
    </li>
    <li>We will use data in connection with legal claims, compliance, regulatory and investigative purposes as necessary (including disclosure of such information in connection with legal process or litigation); and</li>
</ul>

<p><u><b>Where you give us your consent, we will use your personal data:</b></u></p>

<ul>
    <li>To send you newsletters and other promotional material about our Services and to use technologies to check if these have been received and opened to help make our communications relevant to you;
    </li>
    <li>To place cookies and use similar tracking technologies to personalise content and advertising for you and to make our content function better;</li>
    <li>To invite you to take part in market research (where consent is required); and</li>
    <li>On other occasions where we ask you for consent, we will use the data for the purpose which we explain at that time.</li>
    
</ul>

<p><u><b>For purposes which are required by law:</b></u></p>

<ul>
    <li>
        In response to requests by government or law enforcement authorities conducting an investigation.
    </li>
</ul>

<h1><b>WHO WILL WE SHARE THIS DATA WITH, WHERE AND WHEN?</b></h1> 

<ul>
    <li>
        With your consent (where required), we share your personal data with trusted third party service providers, suppliers, affiliates, divisions, partners, sponsors, agents and representatives we have engaged to perform business-related functions on our behalf. For example, to: (i) conduct research and analytics; (ii) create content; (iii) provide customer support services; (iv) conduct or support marketing; (v) fulfil orders; (vi) handle payments; (vii) host Services; (viii) administer contests; and (ix) maintain databases.
    </li>
</ul>

<p>In all cases, the third party is acting on takzin.com, express instructions and in accordance with this Policy, confidentiality and levels of security.</p>

<p><u><b>Without your consent, we share your personal data:</b></u></p>

<ul>
    <li>
        With third party platforms such as Facebook or Google to send you targeted advertisements on our behalf;
    </li>
    <li>
        In response to legal process, for example, in response to a court order or a subpoena, a law enforcement or government agency's request;
    </li>
    <li>
        With third parties if this will help us to enforce our policies and terms of use, to check for potential illegal activity (such as copyright infringement or fraud) or to protect the safety of other users of the Services; and
    </li>
    <li>
        If we, or one of our business units, undergoes a business transition, like a merger, acquisition by another company, or sale of all or part of our assets.
    </li>
</ul>

<p><b>How long will we retain your data?</b></p>

<ul>
    <li>
        Where we process personal data for marketing purposes or with your consent, we process the data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or to process your data indefinitely so that we can respect your request in future.
    </li>
    <li>
        Data from both primary & backup Servers can be purged within 30 days in case of Client request.
    </li>
</ul>

<h1><b>SECURITY MEASURES</b></h1> 

<ul>
    <li>
        We have put in place physical, electronic, and managerial procedures designed to help prevent unauthorised access, to maintain data security, and to correctly use the personal data we collect online. These safeguards vary based on the sensitivity of the personal data that we collect and store.

    </li>
    <li>
        Although we take appropriate measures to safeguard against unauthorised disclosures of personal data, we cannot assure you that your personal data will never be disclosed, altered or destroyed in a manner that is inconsistent with this privacy notice.
    </li>
</ul>

<p><b>Children's Privacy</b></p>

<p>Our Services do not target and are not intended to attract children under the age of 18. We do not knowingly solicit personal data from children under the age of 18 or send them requests for personal information.</p>

<h1><b>CORRECTIONS AND UPDATES</b></h1> 
<ul>
    <li>
        If you wish to modify or update any information the Website has received, please contact contact@takzin.com
    </li>
</ul>

<h1><b>MODIFICATIONS OF THE PRIVACY POLICY</b></h1> 
<p>
    The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore the Customers’ are encouraged to frequently visit these sections in order to be updated about the changes on the website. Modifications will be effective on the day they are posted”.
</p>

<p>
    This Privacy Policy was updated in December 2020
</p>

</div>