import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common'; 

import { GetRatesFilterModalComponent } from 'src/app/components/get-rates-filter-modal/get-rates-filter-modal.component';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { SEOService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';

interface Service {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'takzin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  services: Service[] = [
    {value: 'storage', viewValue: 'Storage Services'},
    {value: 'transport', viewValue: 'Transport Services'},
    {value: 'becomeVendor', viewValue: 'Becoming a Vendor'}
  ];

  public servicesForm: FormGroup;
  initialValue = '';

  get service() {
    return this.servicesForm!.get('service');
  }

  constructor(public dialog: MatDialog,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly seoService: SEOService,
    private readonly config: NgbCarouselConfig
  ) { 
    config.interval = 10000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;
    config.pauseOnFocus = true;
    this.servicesForm = new FormGroup({
      service: new FormControl('', [])
    })
  }

    @ViewChild('howItWorks') public howItWorks:ElementRef;
    
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('selection');
    }
    
    const pageTitle = 'takzin | Warehouse Storage in Dubai | UAE';
    this.seoService.updateTitle(pageTitle);

    const pageDescription = 'Warehouse Storage in Dubai, UAE. Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs.';
    this.seoService.updateDescription(pageDescription);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(GetRatesFilterModalComponent, {panelClass: 'takzin-modal'});

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.router.navigate(['listing']);
        localStorage.setItem('selection',JSON.stringify(result));
      }
    });
  }

  public howItWorksStruc():void {
        this.howItWorks.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  }

  transportQuoteRequest(){
    this.router.navigate(['transport-listing', {requestType: "quote"}]);
  }
  becomeVendor(){
    this.router.navigate(['become-vendor']);
  }

  public selectService(event$){
    switch(event$.value.value) {
      case 'storage': {
        this.openDialog();
        break;
      }
      case 'transport': {
        this.router.navigate(['transport-listing']);
        break;
      }
      case 'becomeVendor': {
        this.becomeVendor();
        break;
      }
      default: {
        break;
      }
    }
    this.service.setValue('');
  }
}
