import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AreaLocation, WarehouseLocation } from 'src/app/models/wareshousedetails.model';
import { FilterSelectionModel } from '../models/filter-selection.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class WarehouseService {

    url = environment.warehouseData;

    constructor(
        private http: HttpClient
    ) { 
    }

    getWarehouse() {
        return this.http.get<WarehouseLocation[]>(this.url);
    }

    getFilteredWareHouseDetails(filter: FilterSelectionModel) {
        return this.http.get<WarehouseLocation[]>(this.url)
        .pipe(
            map(
                (data) => {
                    var responseData = data.filter(warehouse => warehouse?.emirate?.toLowerCase() === filter?.emirate?.toLowerCase())[0];
                    if(responseData === undefined || responseData.locations.length < 1)
                    {
                        return responseData;
                    }

                    if(filter.item === "DG Cargos (Class 5 - 9)" || 
                    filter.item === "DG Cargos (Class 1 - 4)")
                    {
                        responseData.locations = responseData.locations.filter(location => location.isDGCargo);
                    }

                    if(filter.land?.toLowerCase() === "freezone")
                    {
                        responseData.locations = responseData.locations.filter(location => location.isFreezone);
                    }
                    else if(filter.land?.toLowerCase() === "main land"){
                        responseData.locations = responseData.locations.filter(location => !location.isFreezone);
                    }

                    if(filter.item === "Medical")
                    {
                        responseData.locations = responseData.locations.filter(location => location.isMedical);
                    }

                    responseData.locations.forEach(location => {
                        location.storage = location.storage.filter(storage => {
                            let flag = filter.storage.includes(storage.type);
                            return filter.storage.includes(storage.type);
                        })
                    });
                    
                    responseData.locations = responseData.locations.filter(location => {
                        return location.storage.length > 0;
                    });
                    
                    responseData.locations.forEach(location => {
                        location.storage.forEach(storage => {
                            storage.cbm = storage.cbm?.sort((one, two) => (one < two ? -1 : 1));
                            storage.pallet = storage.pallet?.sort((one, two) => (one < two ? -1 : 1));
                            storage.sqft = storage.sqft?.sort((one, two) => (one < two ? -1 : 1));
                            if(storage.dgCargo)
                            {
                                storage.dgCargo.cbm = storage.dgCargo?.cbm?.sort((one, two) => (one < two ? -1 : 1));
                                storage.dgCargo.pallet = storage.dgCargo?.pallet?.sort((one, two) => (one < two ? -1 : 1));
                            }
                            if(storage.medical)
                            {
                                storage.medical.cbm = storage.medical?.cbm?.sort((one, two) => (one < two ? -1 : 1));
                                storage.medical.pallet = storage.medical?.pallet?.sort((one, two) => (one < two ? -1 : 1));
                            }
                        });
                        location.handling.forEach(handling => {
                            handling.percbm = handling.percbm?.sort((one, two) => (one < two ? -1 : 1));
                            handling.perpallet = handling.perpallet?.sort((one, two) => (one < two ? -1 : 1));
                        })
                    })
                return responseData;
            })
        );
    }
}

