import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private readonly seoService: SEOService
  ) { }

  ngOnInit(): void {
    const pageTitle = 'Warehouse Storage and Transport in Dubai | About takzin';
    this.seoService.updateTitle(pageTitle);
    const pageDescription = 'Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs. About takzin.';
    this.seoService.updateDescription(pageDescription);
  }

}
