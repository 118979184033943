<div class="container listing-container">
    <div class="row">
        <div class="col-12">
            <!-- <h2 class="text-left">Warehouse List</h2> -->

            <button class="listing-button takzin-button-primary" (click)="openModifySearchDialog()">Modify Search</button>
        </div>
        <div class="container-fluid mt-3 pt-3"></div>
    </div>
    <div class="row" *ngIf="!isLoading && productCardList.length > 0">
        <div class="col-12">
            <h2 class="listing-info-text">The Price Range provided for different regions is retrieved from multiple partnered vendors/warehouses.</h2>
        </div>
        <div class="container-fluid mt-3 pt-3"></div>
    </div>
    <takzin-loading *ngIf="isLoading"></takzin-loading>
    <div class="row" *ngIf="!isLoading">
        <ng-container *ngFor='let productCard of productCardList'>
            <div class="col-sm-6 col-md-4 col-xs-12 col-12 mb-4 ">
                <div class="card">
                    <div class="card-title" *ngIf="!isMedical; else elseBlock">
                        <h1 class="">{{productCard.area}}, {{productCard.emirate}}</h1>
                    </div>
                    <ng-template #elseBlock>
                        <div class="card-title">
                            <h1 class="">{{productCard.area}}, {{productCard.emirate}} (MOH Approved)</h1>
                        </div>
                    </ng-template>

                    <div class="card-body " style="background-color: #f9f9f9;">
                        <p class="card-text text-left"><b>Storage: </b> {{productCard.storage}}
                        </p>

                        <p class="card-text text-left"><b>Item: </b> {{productCard.item}}
                        </p>

                        <p class="card-text text-left"><b>Price Range: </b> {{productCard.rangeValue}}
                        </p>

                        <b>Handling In: </b> {{productCard.handlingInValue}}
                        <br />
                        <b>Handling Out: </b> {{productCard.handlingOutValue}}

                        <div *ngIf="displayOtherWarning">
                            <br />
                            <p class="card-text text-left displayOtherWarning">
                                <b>Please note: Price Range is subject to change depending on the customized item type.</b>
                            </p>
                        </div>

                    </div>
                    <div class="card-footer ">
                        <button (click)="openGetQuoteDialog(productCard) " class="takzin-button-primary card-button-primary listing-quote-me">Quote Me</button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="productCardList.length < 1" class="col-12 no-search-message">
            Request you to kindly send your requirement in detail at <a href="mailto:contact@takzin.com"> contact@takzin.com</a> or Call Us/Whatsapp at +971 56 783 1004/+971 50 237 3759 and we will get back to you shortly. Thank You for using takzin.com!
        </div>
    </div>
</div>