<div class="container">
    <p dir="ltr" style="line-height:1.38;margin-top:20pt;margin-bottom:0pt;">
        <span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>WEBSITE TERMS AND CONDITIONS</b></span>
    </p>
    <p dir="ltr" style="line-height:1.38;margin-top:20pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">AGREEMENT</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.1.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Max More Shipping L.L.C (Collectively &quot;we&quot;, &quot;us&quot;, &quot;our&quot;) is based in Dubai, the United Arab Emirates which operates the Internet website at www.takzin.com (the &ldquo;Website&rdquo;).</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PLEASE READ THIS&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">TERMS OF USE AGREEMENT</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;CAREFULLY! BY CLICKING &ldquo;SUBMIT, ACCEPTED AND AGREED,&rdquo; OR CONTINUING TO ACCESS AND USE THIS WEBSITE OR THE TECHNOLOGY PLATFORM TO WHICH IT IS RELATED, YOU AGREE TO THIS AGREEMENT WITHOUT RESTRICTION, INCLUDING THE PRIVACY POLICY REFERENCED HEREIN. IT IS A LEGALLY BINDING CONTRACT BETWEEN YOU AND MAX MORE SHIPPING L.L.C. THAT OPERATES &ldquo;THE WEBSITE&rdquo; IF YOU DO NOT WISH TO ABIDE BY THESE TERMS, DO NOT USE THIS TOOL.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By accessing and using the Website, you are expressly consenting to the terms and conditions outlined on this page that are effective as of the date and time you use or access the Website (the &ldquo;Terms&rdquo;). Please read the Terms carefully before accessing or using the information and services available through this Website. The Conditions of Service apply to any products or services that you may &ldquo;book&rdquo; or &ldquo;use&rdquo; using the website www.takzin.com and any associated mobile or digital applications that refer to these Conditions of Service (together, the &ldquo;Platforms&rdquo;) or by telephone via our client relations center (the &ldquo;Client Relations Center&rdquo;).</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These Conditions of Services apply to any booking or services that are requested or placed through the Platforms or Client Relations Center (together with the &ldquo;Service Channels&rdquo;). Please note that before booking or requesting the services, you will be asked to agree to these Conditions of Service. If you do not agree to these Conditions of Service, then you will not be able to receive any quotes or services through the Service Channels. Services concluded through physical points (such as our Suppliers) or third parties (such as authorized Brokers, Agents, Advisors, Freight Forwarders, Trading Companies) are not subject to these Conditions of Services.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.3.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These Terms will constitute a legal agreement between &ldquo;you&rdquo; (the user of this Website) and Max More Shipping L.L.C. If you do not agree to, or cannot comply with, the Terms, please kindly refrain from using this Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.4.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These Terms may be modified from time to time without notice to you and you should therefore regularly review these Terms for any such modifications. If you do not agree to any change to the Terms then you must immediately stop using the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5. takzin.com reserves the right, in its discretion, to change or modify all or any part of these Terms at any time, effective immediately upon notice published on the Website. If at any time the Terms are no longer acceptable to you, you should immediately cease use of the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.6. Certain areas of the Website may be subject to additional terms of use. By using such areas, or any part of them, you agree to be bound by the additional terms of use applicable to such areas. Please refer to our Privacy Policy for information about how we use your data.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.7 takzin.com helps consumers and businesses receive quotes on services that they request. And is also intended to help Users secure an estimate of Storage Costs, Transportation Costs, and other information based on information that consumer users may provide through the site.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:20pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ENQUIRY</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1. To use some of the services or features on the Website you may need to register. When you enquire or request a quote you are required to provide information about yourself that is true, accurate, current, and complete in all respects. Should any of your information change, please notify us immediately at the following email address Contact@takzin.com. We may also change inquiry requirements from time to time.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.2. Although we will not be liable for your losses caused by any unauthorized use of your account or information, you may be liable for our losses or others due to such unauthorized use.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">BOOKING &amp; ENQUIRY ELIGIBILITY</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1. Only individuals (and not legal entities) who (a) have reached the age of legal majority required to enter into contracts; (b) have the legal capacity to enter into contracts, and; (c) use a shipping address in the country or countries that use our Services.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2. To avail of the Service on the Website you will be required to provide your details. In particular, you must provide your real name, phone number, e-mail address, company name, and other requested information as indicated.&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">BOOKING &amp; ENQUIRY PROCESS</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.1. The Booking &amp; Enquiry process of the Platforms will include the following:</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull; Once you have chosen a Service, you may have to fill in the required details as per the 3.2. You may then decide to continue for other Services (subject to availability and quantity limits). Placing a request for a quote does not guarantee availability, which is not confirmed until you receive confirmation of your request from takzin.com.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull; Placing and Confirming Request: When you are ready, the takzin.com service expert will get in touch with you and review the details with you. Placing an inquiry does not guarantee availability for the same, which is not confirmed until you receive a written Confirmation of Booking from takzin.com.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&bull; Review and Confirmation: Once the request is placed, you will receive an auto-email and a Quote_id number along with your request details, including:</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Emirate</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Item Type</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Storage Type</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Handling</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Land</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Value Added</span></p>
        </li>
        <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
            <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Duration</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This email is not an acceptance of your request, just a confirmation that we have received your request. Please make sure that you save the Quote_id number for any future inquiries regarding your request. To confirm the request, we will conduct a review of the information and, if acceptable, will then process your request.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2. You should review all details carefully before completing the payment. Completing the payment means that you confirm this booking and we will proceed according to your request. If you have any questions or concerns or if the Acknowledgement of Request or Booking does not reflect your intentions, you should contact the takzin.com Customer Care Department promptly.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.3. Acceptance of the order and completion of the contract between you and Max More Shipping L.L.C. will be perfected when we enter into a legal agreement with you at which time you will receive an email containing your Quote_id number and confirming that your booking has been confirmed. This Confirmation of Service constitutes our acceptance of your request and indicates the existence of a binding Service contract. Our third-party payment processor will not have the authority to accept a booking of the storage on behalf of Max More Shipping L.L.C (Using takzin.com).</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.4. We reserve the right not to accept or fulfill your request or booking in the event, that we are unable to obtain authorization for payment, or that you do not meet the eligibility criteria set-out within these Terms. Furthermore, we may refuse to process your request or booking for any reason or refuse service to anyone at any time at our sole discretion. We will not be liable to you or any third-party in this respect.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PRICING</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1. All prices shown on the Website pages of the Platforms or quoted by the takzin.com Services Experts exclude Value Added Services, Transportation cost, and other taxes unless otherwise stated.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2. The applicable currency will be in United Arab Emirate Dirham (AED). You should check updated prices and currency carefully.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.3. Services, use, or other taxes will vary based on the location on which the service has been availed.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.4. Please note that we do not provide transportation to certain addresses, such as the military, certain restricted areas, pick-up points, or PO boxes. For further information, please contact the takzin.com Customer Care Department.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.5. Pick-up may be offered to certain locations and the same shall be charged. Please refer to the Platforms or call the takzin.com Customer Care Department for more information. We will inform you by e-mail or by telephone when the Service is ready.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.6. We reserve the right to modify prices and transportation costs at any time without prior notice.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.7. We take reasonable care that the prices of the Service and costs are correct at the time when the relevant information was entered into the system or communicated to you by the takzin.com Service Experts. However, it is always possible that, despite our reasonable efforts, some of the Services offered through our Services Channels or costs may be incorrectly priced. If any of the Services you avail of or request are incorrectly priced, we will contact you as soon as possible to inform you of this error. If we are unable to contact you using the contact details you have provided during the inquiry request process, we will cancel the order and notify you in writing. If we mistakenly accept and process your request where a pricing cost error occurs, we may cancel the order.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.8. Please note that changes to applicable law between the date of your request are placed and the date you are sent a written Confirmation of Services may result in changes to the taxes associated with your booking. If the resulting change is an increase in the taxes that you are charged, we will contact you and ask that you reconfirm your order.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.9. Our prices are also subject to change without notice. We apologize for any inconvenience that this may cause. If you have any questions, please do not hesitate to contact one of our Service experts at contact@takzin.com&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.10. We are happy to negotiate prices on our Services.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PAYMENT</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1. Payment to be made by cheque or Bank Transfer Only. Depending upon the means of payment, we may require additional information, including specific forms of identification.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You expressly authorize us to perform security checks, where we deem necessary, to transmit or to obtain information (including any updated information) about you to or from third parties from time to time, including but not limited to your payment details, to authenticate your identity.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Once the transaction is complete, the payment will be debited and cleared from your account.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You will need to communicate to the takzin.com Services Expert to complete payment details or follow the steps as advised by the takzin.com Customer Care Department.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">We may accept bank wire transfers for booking at our sole discretion. We do not charge a fee for bank wire transfers for bookings made through the Services Channels or Customer Care Department, however, some financial institutions may charge a fee for using a bank wire transfer. We require that all bank wire transfer orders placed by telephone be confirmed through an order approval process. We may acknowledge a bank wire transfer order, but the order will not be processed until the payment is confirmed by us by email. If your wire transfer payment is not credited into our bank account within seven (7) days after you have placed your order, your order will be canceled.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">GENERAL PROVISIONS</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.1. If any provision or part of a provision, of these Conditions of Service, is found to be illegal, invalid or unenforceable, that provision or part-provision shall be deemed not to form part of these Conditions of Service, and the legality, validity, or enforceability of the remainder of the provisions of these Conditions of Service shall not be affected, unless otherwise required by operation of applicable law.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.2. These Conditions of Service (and associated terms incorporated by reference) constitute the entire agreement between you and us concerning the request of services, and replace and extinguish all prior agreements, draft agreements, arrangements, undertakings, or collateral contracts of any nature made by the parties, whether oral or written, with such subject matter.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.3. We are not responsible for any failure or delay in performing or complying with our obligations under these Conditions of Service which arises from any cause beyond our reasonable control.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.4. The waiver by us of a breach of any provision of these Conditions of Service will not operate to be interpreted as a waiver of any other or subsequent breach.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.5. This contract is between Max More Shipping L.L.C. and you. No other person shall have any rights to enforce any of its terms.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INFORMATION ON OUR SITE</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">At takzin.com, we make every attempt to ensure that our online Service is as accurate and complete as possible. To allow you to view our Service in great detail, some information may appear larger or smaller than its actual size in our photographs; and since every computer monitor is set differently, color and size may vary slightly.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">USE OF WEBSITE CONTENT</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.1. All information, text, materials, graphics, names, images, designs, tag lines, logos, button icons, images, video and audio clips, trademarks, trade names, service names (whether registered or not), advertisements, layout, arrangement, graphical user interface, look and feel, and control features of the Website (collectively, &ldquo;Website Content&rdquo;) are provided to you as a service to allow you and other users to browse our services.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.2. You are only permitted to access and use this Website for your personal, non-commercial purposes, and you must not:</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(a) modify, publish, transmit, transfer, sell, reproduce, create derivative works from, distribute, perform, display, or in any way exploit any of the Website Content, in whole or in part, except as otherwise expressly permitted in these Terms;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(b) use this Website in violation of any applicable law or regulation.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(c) use this Website to transmit, distribute, store or destroy material, including without limitation Website Content, in a manner that will infringe the copyright, trademark, trade secret, or other intellectual property rights of takzin.com or others or violate the privacy, publicity, or other personal rights of others;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(d) sell, modify or delete the Website Content or reproduce, display, publicly perform, distribute, or otherwise use the Website Content in any way for any public or commercial purpose;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(e) access data not intended for you or logging into a server or account which you are not authorized to access;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(f) attempt to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(g) copy or adapt the HTML code created to generate any Website Content or the pages making up the Website;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">(h) use the Website Content impersonate or otherwise misrepresent your identity or affiliation with takzin.com or any other person or entity;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(i) use the Website Content to send unsolicited mail or email, make unsolicited phone calls or send unsolicited faxes regarding promotions and/or advertising of products or services;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(j) use any device, software, or routine to interfere or attempt to interfere with the proper working of the Website or any activity being conducted on the Website, including without limitation, material that contains viruses, Trojan horses, worms, time bombs, cancelbots, or other computer programming routines or engines that are intended to damage detrimentally interfere with, surreptitiously intercept or expropriate any system, data or information;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(k) attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or in any way making up a part of the Website; or</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(l) frame or link to any Website Content or information available from the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9.3. You shall not copy, store, redistribute or publish any Website Content without the express permission of takzin.com.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INTELLECTUAL PROPERTY</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Except as otherwise expressly stated, takzin.com owns or is an approved licensee to the copyright and all other Intellectual Property contained in the Website and the Website Content, including but not limited to the &quot;look and feel&quot; of the Website, all HTML and other code contained in the Website, all text images or links, all product names and images, trade names, service names, tag lines, or logotype distinguished in form, text, or otherwise from surrounding text (e.g. all capital letters), (collectively, &quot;Marks&quot;) are trademarks owned by or licensed to takzin.com unless otherwise noted. The Marks on the Website are variously protected by the laws of the United Arab Emirates and other countries. No use of any of these marks may be made without the prior, written authorization of takzin.com. Any reproduction or redistribution of the Marks is prohibited and may result in civil and criminal penalties. Violators will be prosecuted to the fullest extent permissible under applicable law. Without limiting the foregoing, copying and use of the Marks to any other server, location, or support for publication, reproduction, or distribution is expressly prohibited.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Intellectual Property&rdquo; means all trademarks, patents, inventions (whether patentable or not), copyrights, moral rights, product designs, design rights, trade names, business names, service marks, logos, service names, trade secrets, domain names, database rights, and any other intellectual property or proprietary rights (whether registered or unregistered) including rights in computer software and all registrations and applications to register any of the aforesaid items.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">INDEMNITY&nbsp;</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You agree to indemnify, defend and hold Us and Our Associated Companies (as defined below) harmless from and against any claims, liability, suits, judgments, litigation costs, causes of action, demands, recoveries, damages (actual and consequential), fines, penalties, losses, costs and expenses (including attorneys&rsquo; fees) of any kind or nature incurred by Us and/or Our Associated Companies arising from or in any way related to your use of this Website, including, by third parties as a result of your breach of these Terms or any other terms referenced herein your violation of any law or the rights of a third party, or your use of this Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;Associated Companies&rdquo; means any company, partnership, or other entity controlled by, controlling, or in common control of its parent or any subsidiary, including the management and directors of such entities. A person, company, partnership, or other entity shall be deemed to control another person, company, partnership, or other entity if the former person, company, partnership, or other entity possesses, directly or indirectly, the power to direct or cause the direction of, the management and policies of the other person, company partnership or other entity whether through the ownership of voting securities or partnership interests, representation on its board of directors or similar governing body, by contract or otherwise;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">YOUR ACTIVITY AND TERMINATION OF YOUR SERVICE</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.1. You use this Website at your sole risk. You agree that you will be personally responsible for your use of this Website and all of your communication and activity on this Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.2. You agree that you shall not remove, obscure, or alter any proprietary rights notices (including copyright and trademark notices) which may be affixed to or contained within the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.3. You agree not to collect or harvest any personally identifiable information, including account names, from the Website, nor to use the communication systems (if any) provided by the Website for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Website concerning any submissions made by them.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.4. takzin.com reserves the right, in its sole discretion, to restrict, suspend or terminate your access to all or any part of the Website at any time for any reason without prior notice or liability. We may change, suspend or discontinue all or any aspect of the Website at any time, including the availability of any feature, database, or Website Content, without prior notice or liability. Notwithstanding such termination or suspension, you will continue to be subject to these Terms. Except as otherwise expressly stated in these Terms, We shall not be liable to you or any third party for any modification, termination, suspension, or discontinuation of the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12.5. You acknowledge and agree that any materials, including but not limited to questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials, or other information, regarding this site, We, or our services that are provided by you are non-confidential and shall become the sole property of takzin.com. We will own exclusive rights, including all intellectual property rights, and will be entitled to the unrestricted use and dissemination of these materials for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You grant Us and our affiliates and sublicensees the right to use the name that you submit in connection with such content if they choose. You represent and warrant that (a) you own and control all of the rights to the content that you submit, or that you otherwise have the right to submit such content to this site; (b) the content is accurate and not misleading, and (c) use and posting of the content you supply will not violate any rights of or cause injury to any person or entity.&nbsp;</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DISCLAIMERS OF WARRANTIES; LIMITATIONS OF LIABILITY</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.1. The Website and the Website Content are provided on an &quot;AS-IS&quot;, &ldquo;without faults&rdquo; and &ldquo;as available&rdquo; basis and, to the extent lawful, with no warranties whatsoever. We expressly disclaim (to the fullest extent permitted by law) any express, implied, and statutory warranties, including, without limitation, the warranties of merchantability, fitness for a particular purpose, freedom from computer virus, title, and non-infringement of proprietary rights.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.2. We and our Associated Companies (as defined herein) are not liable for any direct, indirect, punitive, incidental, special, or consequential damages or loss (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) arising out of, or in a way connected with, the use or inability to use the Website and the Website Content, or inability to enter into a sales promotion, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not we are advised of the possibility of such damages.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.3. Without limiting the above paragraph, We do not accept any liability in respect of your involvement in any mediation, arbitration, tribunal hearing, court proceeding, or other proceedings (of whatever nature) with any third-party advertiser in any way.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.4. We do not represent or endorse the accuracy or reliability of any Website Content and you acknowledge that any reliance upon such Website Content shall be at your sole risk.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.5. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of any email due to technical problems or traffic congestion on the Internet or this Website or combination thereof, including any injury or damage to any users or any person&apos;s computer related to or resulting from participation or downloading materials in connection with the Website.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.6. Documents available from the web server were prepared as an account of work sponsored by a client or our work. Neither Us nor any client thereof, or any of their employees, makes any warranty, express or implied, or assumes any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, apparatus, product, or process disclosed or represents that its use would not infringe privately owned rights.</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13.7. We shall not be liable to you or be deemed in breach of these Terms because of any delay in performing, or any failure to perform any of our respective obligations under these Terms if the delay or failure was due to any cause beyond our reasonable control. This may include any act of god; legislation; war; fire; flood; drought; inadequacy or unsuitability of any instructions, electronic files, or other data or materials. Failure of power supply; lock-out, strike, or other action taken by employees in contemplation or furtherance of a dispute; or owing to any inability to procure materials required for the performance of the Terms.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14.</span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">GOVERNING LAW AND JURISDICTION</span></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14.1. These Terms as well as your access to our Website are subject to and governed by the laws of the United Arab Emirates as applied in the Emirate of Dubai without giving effect to conflicts of law principles thereof. Any dispute regarding these Terms or our handling of your Personal Information and General Information is subject to the exclusive jurisdiction of the Courts in Dubai.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Thank you for your cooperation. We hope you find this Website helpful and convenient to use. If you have questions or comments regarding this website, please Contact Us.</span></p>
    <p><br></p>
    <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:20pt;"><span style="font-size:11pt;font-family:Arial;color:#0e101a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This document was last updated in December 2020.</span></p>
    </div>