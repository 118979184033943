<div class="container form">
    <div class="row">
        <div class="col-md-12">
            <h1 mat-dialog-title class="dialog-title ">REQUEST A WAREHOUSING QUOTE
                <button type="button" class="close" aria-label="Close" (click)="onCancelClick()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h1>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm.value)">
                <div class="get-quotes-dialog">
                    <!--Name-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Name
                        </label>
                        <input type="text" value="{{name}}" id="name" formControlName="name" class="form-control" required>
                        <div *ngIf="name!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="name!.errors!.required">
                                Name is required.
                            </div>
                        </div>
                    </div>

                    <!--Email-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Email
                        </label>
                        <input type="text" value="" id="email" formControlName="email" placeholder="yourname@example.com" class="form-control" required>
                        <div *ngIf="email!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="email!.errors!.required">
                                Email is required.
                            </div>
                            <div *ngIf="email!.errors!.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>

                    <!--CompanyName-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Company Name
                        </label>
                        <input type="text" value="" id="companyName" formControlName="companyName" class="form-control" required>
                        <div *ngIf="companyName!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="companyName!.errors!.required">
                                Company Name is required.
                            </div>
                        </div>
                    </div>

                    <!--Contact Number-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Contact Number
                        </label>
                        <input type="text" inputmode="numeric" pattern="[0-9]*" digitOnly autocomplete="off" value="" id="contactNo" formControlName="contactNo" class="form-control">
                        <div *ngIf="contactNo!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="contactNo!.errors">
                                Contact Number can only be numbers from 0-9.
                            </div>
                        </div>
                    </div>

                    <!--Subject-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Subject
                        </label>
                        <input type="text" value="" id="subject" formControlName="subject" class="form-control">
                        <div *ngIf="subject!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="subject!.errors!.required">
                                Subject is required.
                            </div>
                        </div>
                    </div>

                    <!--Message-->
                    <div class="form-group">
                        <label class="takzin-modal-control-label required-label">
                            Message <span>(Please describe your requirement in detail)</span>
                        </label>
                        <textarea value="" id="message" formControlName="message" class="form-control" required></textarea>
                        <span class="message-length-info"><small>{{message.value.length }} of 2000 characters</small></span><br />
                        <div *ngIf="message!.invalid && showValidationMessages && message!.errors!.required" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="message!.errors!.required">
                                Message is required.
                            </div>
                        </div>
                        <div *ngIf="message!.invalid && message!.errors!.maxlength" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="message!.errors!.maxlength">
                                You have exceeded the maximum character limit of 2000 characters.
                            </div>
                        </div>
                    </div>

                    <!--Attachment -->
                    <div class="form-group">
                        <label class="takzin-modal-control-label">
                            Additional Details
                        </label>
                        <div class="row">
                            <div class="col-md-3 ">
                                <label class="takzin-modal-attachment-label" for="attachment"> Upload File</label>
                                <input class="form-control" type="file" id="attachment" formControlName="attachment" (change)="onFileChange($event)" style="display: none;" (click)="OnFileClick($event)">
                            </div>
                            <div class="col-md-9">
                                <div *ngIf="!fileSizeValidator && !fileTypeValidator && file">
                                    <label>{{file.name}}
                                    <label class="takzin-modal-close-button" (click)= "onCloseClick()" >&times;</label>
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="fileSizeValidator" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="fileSizeValidator">
                                The file that you are trying to upload exceeds the limit of 5MB.
                            </div>
                        </div>

                        <div *ngIf="fileTypeValidator" class="alert alert-danger takzin-validation-msg">
                            <div *ngIf="fileTypeValidator">
                                The attached file type is not supported. Please try using .xls, .xlsx, .pdf, .doc, .docx, .png, .jpg, .jpeg file types.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="dialog-footer">
                    <button type="submit" class="takzin-button-primary takzin-modal-button storage-send-request" id="send-quote-request">Send Request</button>
                    <button (click)="onCancelClick()" type="button" class="takzin-button-secondary takzin-modal-button">Cancel</button>
                    <br />
                    <small>By clicking the above button,
                        you agree to our <a routerLink="/terms" target="_blank" routerLinkActive="active" alt="Terms of Service">Terms of Service</a> and have read and understood our <a routerLink="/privacy" target="_blank" routerLinkActive="active" alt="Privacy Policy">Privacy Policy.</a></small>
                </div>
            </form>
        </div>
    </div>
</div>