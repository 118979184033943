<header class="takzin-header">
    <div class=" header-div container-fluid ">
        <nav class="container navbar fixed-top navbar-expand-lg navbar-light bg-white">
            <h3 class="header-title "><a class="header-title" routerLink="" routerLinkActive="active">ta<span class="primary-color">ḵ</span>zīn</a></h3>
            <button class="takzin-button-primary takzin-header-button " (click)="openDialog()" [hidden]="hideGetStarted">Get Started</button>
            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div class= "navbar-button">
                    <span class="navbar-toggler-icon"></span>
                    <span class="close-icon">✖</span>
                </div>
          </button>

            <div class="collapse navbar-collapse header-nav" id="navbarNavAltMarkup">
                <div class="nav navbar-nav ml-auto">
                    <a class="nav-item nav-link" routerLink="" routerLinkActive="active" alt="HOME" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLinkActiveOptions]="{exact:true}">HOME <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" routerLink="/about-us" routerLinkActive="active" alt='ABOUT US' data-toggle="collapse" data-target=".navbar-collapse.show">ABOUT US</a>
                    <a class="nav-item nav-link" routerLink="/transport-listing" routerLinkActive="active" alt='TRANSPORT' data-toggle="collapse" data-target=".navbar-collapse.show">TRANSPORT</a>
                    <a class="nav-item nav-link" routerLink="/become-vendor" routerLinkActive="active" alt='BECOME A VENDOR' data-toggle="collapse" data-target=".navbar-collapse.show">BECOME A VENDOR</a>
                    <a class="nav-item nav-link" routerLink="/contact-us" routerLinkActive="active" alt='CONTACT US' data-toggle="collapse" data-target=".navbar-collapse.show">CONTACT US</a>
                    <button class="takzin-button-primary takzin-header-nav-button header-get-started" (click)="openDialog()" [hidden]="hideGetStarted">Get Started</button>
                </div>
            </div>
        </nav>


    </div>

</header>