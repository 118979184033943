import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { 
  HomeComponent, 
  ProductsComponent,
  ContactUsComponent,
  TermsComponent,
  ThankyouComponent,
  PrivacyComponent,
  AboutComponent,
  BecomeVendorComponent,
  TransportListingComponent,
  ErrorPageComponent
} from './components';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'terms', component: TermsComponent, pathMatch: 'full' },
  { path: 'privacy', component: PrivacyComponent, pathMatch: 'full' },
  { path: 'listing', component: ProductsComponent, pathMatch: 'full' },
  { path: 'thankyou', component: ThankyouComponent, pathMatch: 'full' },
  { path: 'about-us', component: AboutComponent, pathMatch: 'full' },
  { path: 'become-vendor', component: BecomeVendorComponent, pathMatch: 'full' },
  { path: 'transport-listing', component: TransportListingComponent, pathMatch: 'full' },
  { path: '**', component: ErrorPageComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
