<div class="contact-us">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="white">We Can Find You a Warehouse in the Emirates</h1>
            </div>
        </div>
    </div>
</div>
<!-- contact-form -->
<div class="container form">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-8">
            <div class="row">
                <div class="form-group col-md-12 contact-us-text">
                    <p style="">Stop searching for vendors in the Warehousing industry! We can help you find quality Warehouse Space.</p>
                    <ul>
                        <li>Our management team has pre-screened vendors in every Emirate.</li>
                        <li>We can connect you with multiple Warehouses so that you can get competitive price quote, free, no obligation, and confidentially if you choose!</li>
                    </ul>
                    <p>Please use the phone number, email or send us a message below. </p>
                    <p>Thank you for using taḵzīn.
                    </p>
                </div>
            </div>

            <div class="vl"></div>
        </div>
        <div class="col-md-3 contact-right-info">
            <p></p>
            <p>Email</p>
            <a href="mailto:contact@takzin.com">contact@takzin.com</a>
            <p></p>
            <p>Call Us</p>
            <a href="tel:+971 56 783 1004">+971 56 783 1004</a><br />
            <a href="tel:+971 50 237 3759">+971 50 237 3759</a><br /><br />
            <div class="logo">
                <a href="https://www.facebook.com/Takzincom-102811395082970/" class="facebook-logo" target="_blank">
                </a>
                <a href="https://www.linkedin.com/company/takzin-com/" class="linkedin-logo" target="_blank">
                </a>
            </div>
        </div>
    </div>

    <div class="row message-form-row">
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-10">
            <h1 class="contact-us-form-header">Send Us a Message</h1>
            <form [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm.value)">

                <!--Name-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Name
                        </label>
                    <input type="text" value="" id="name" formControlName="name" class="form-control" required>
                    <div *ngIf="name!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="name!.errors!.required">
                            Name is required.
                        </div>
                    </div>
                </div>

                <!--Email-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Email
                        </label>
                    <input type="text" value="" id="email" formControlName="email" placeholder="yourname@example.com" class="form-control" required>
                    <div *ngIf="email!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="email!.errors!.required">
                            Email is required.
                        </div>
                        <div *ngIf="email!.errors!.pattern">
                            Email is invalid.
                        </div>
                    </div>
                </div>

                <!--CompanyName-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Company Name
                        </label>
                    <input type="text" value="" id="companyName" formControlName="companyName" class="form-control" required>
                    <div *ngIf="companyName!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="companyName!.errors!.required">
                            Company Name is required.
                        </div>
                    </div>
                </div>

                <!--Contact Number-->
                <div class="form-group">
                    <label class="takzin-modal-control-label">
                            Contact Number
                        </label>
                    <input type="text" inputmode="numeric" pattern="[0-9]*" digitOnly autocomplete="off" value="" id="contactNo" formControlName="contactNo" class="form-control">
                    <div *ngIf="contactNo!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="contactNo!.errors">
                            Contact Number can only be numbers from 0-9.
                        </div>
                    </div>
                </div>

                <!--Subject-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Subject
                        </label>
                    <input type="text" value="" id="subject" formControlName="subject" class="form-control">
                    <div *ngIf="subject!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="subject!.errors!.required">
                            Subject is required.
                        </div>
                    </div>
                </div>

                <!--Message-->
                <div class="form-group">
                    <label class="takzin-modal-control-label required-label">
                            Message <span>(Please describe your requirement in detail)</span>
                        </label>
                    <textarea value="" id="message" formControlName="message" class="form-control" required></textarea>
                    <span class="message-length-info"><small>{{message.value.length }} of 2000 characters</small></span><br />
                    <div *ngIf="message!.invalid && showValidationMessages && message!.errors!.required" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="message!.errors!.required">
                            Message is required.
                        </div>
                    </div>
                    <div *ngIf="message!.invalid && message!.errors!.maxlength" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="message!.errors!.maxlength">
                            You have exceeded the maximum character limit of 2000 characters.
                        </div>
                    </div>
                </div>

                <!--Attachment -->
                <div class="form-group">
                    <label class="takzin-modal-control-label">
                        Additional Details
                    </label>
                    <div class="row">
                        <div class="col-md-2 ">
                            <label class="contact-us-attachment" for="attachment"> Upload File</label>
                            <input class="form-control" type="file" id="attachment" formControlName="attachment" (change)="onFileChange($event)" style="display: none;" (click)="OnFileClick($event)">
                        </div>
                        <div class="col-md-8">
                            <div *ngIf="!fileSizeValidator && !fileTypeValidator && file">
                                <label>{{file.name}}
                                   <label class="contact-us-close-button" (click)= "onCloseClick()" >&times;</label>
                                </label>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="fileSizeValidator" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="fileSizeValidator">
                            The file that you are trying to upload exceeds the limit of 5MB.
                        </div>
                    </div>

                    <div *ngIf="fileTypeValidator" class="alert alert-danger takzin-validation-msg">
                        <div *ngIf="fileTypeValidator">
                            The attached file type is not supported. Please try using .xls, .xlsx, .pdf, .doc, .docx, .png, .jpg, .jpeg file types.
                        </div>
                    </div>
                </div>

                <button type="submit" class="takzin-button-primary takzin-modal-button">Let's Go!</button>
                <br />
                <div class="terms-text">
                    <small>By clicking the above button,
                        you agree to our <a routerLink="/terms" target="_blank" routerLinkActive="active" alt='Terms of Service'>Terms of Service</a> and have read and understood our <a routerLink="/privacy" target="_blank" routerLinkActive="active" alt="Privacy Policy">Privacy Policy.</a></small>
                </div>
            </form>
        </div>
        <div class="col-md-1">&nbsp;</div>
    </div>
</div>