<div class="container-fluid">
    <div class="row">
        <div class="container-fluid transport-home">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="transport-home-container-title">Move It With takzin<span class="primary-color"></span></h1>
                        <h6 class="transport-home-container-subtitle">
                            <p>Efficient, Reliable & Affordable Marketplace for Trucking and Transportation Services in the Emirates.</p>
                        </h6>
                        <div class="button-container">
                            <button class="transport-home-button takzin-button-primary transport-get-started" (click)="transportQuoteRequest()">Get Started</button>
                            <button class="transport-home-button takzin-button-secondary transport-howitworks" (click)="howItWorksStruc()">How to Use!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row transport-row">
        <div #howItWorks class="col-md-6 truck-image-one">
            <h6 class="truck-details-title">Our wide range of trucks</h6>
            <ngb-carousel class="carousel">
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>1 Ton</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image one-ton-image-custom">
                                <img src="/assets/images/one-ton.png" height="130" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                1 ton vehicle type is generally used for domestic level transportation. It could be used to overcome truck restriction in particular places. It is mainly to cover small loads from one location to another.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>3 Ton</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image three-ton-image-custom">
                                <img src="/assets/images/three-ton.png" height="130" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                3 Ton vehicle type is used for both industrial as well as domestic level transportation. It is the most used vehicle when it comes to the distribution of goods such as FMCG, furniture and electronics.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>7 Ton</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image">
                                <img src="/assets/images/seven-ton.svg" height="200" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                7 ton heavy duty trailers are ideal for hauling the heaviest of loads and possess ramps that make loading and unloading easy and your equipment secure.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>10 Ton</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image">
                                <img src="/assets/images/ten-ton.svg" height="200" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                10 ton heavy duty trailers are ideal for hauling compact construction equipment. It is commonly used to transfer heavy industrial materials and possess highly powered ramps, which makes loading and unloading equipment safe and efficient.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>Container Flatbed 40"</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image">
                                <img src="/assets/images/flatbed-trailer.svg" height="200" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                Container Flatbed trucks are one of the most common trailers in the road transport industry. This type of trailer is typically used for transporting high-value goods with added security.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>Curtain Trailer 40"</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image">
                                <img src="/assets/images/curtain-trailer.svg" height="200" width="300" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                Curtain trailers trucks are designed to give easy and independent access to all parts of a load at any point along a route which means multiple drop loads are easily doable from a single trailer.
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="container-fluid truck-details-container">
                        <div class="row truck-details-header">
                            <div class="col-md-12">
                                <h6>Flatbed Trailer 40"</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 truck-details-image flatbed-trailer-image-custom">
                                <img src="/assets/images/flatbed-trailer.png" height="100" width="350" />
                            </div>
                        </div>
                        <div class="row truck-details-text">
                            <div class="col-md-12">
                                Flatbed trailer is typically used for transporting heavy, oversized and insensitive goods. It is mainly used for industrial level transportation. It has a bed type dimension that is suitable to accommodate heavy loads.
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
        <div class="col-md-6">
            <div #howItWorks class="container-fluid how-to-use-container">
                <div class="row how-it-works-header ">
                    <div class="col-md-12 ">
                        <h6 class="how-it-works-subtitle ">
                            <p>Move It with Takzin</p>
                        </h6>

                        <h1 class="how-it-works-title">How to Use!</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="container how-it-works-body ">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="how-it-works-header ">
                                        Search
                                    </h6>
                                    <h6 class="how-it-works-desc ">
                                        Select from the wide range of trucks provided by takzin with Pick Up and Drop location.
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="how-it-works-header ">
                                        Pricing
                                    </h6>
                                    <h6 class="how-it-works-desc ">
                                        Check the competitive prices takzin offers for your trucking needs.
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="how-it-works-header ">
                                        Request
                                    </h6>
                                    <h6 class="how-it-works-desc ">
                                        Request a Transport Quote by providing detailed information of your requirement.
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="how-it-works-header ">
                                        Transport
                                    </h6>
                                    <h6 class="how-it-works-desc ">
                                        Be rest assured as we’re committed to delivering the best transportation service to you.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="container-fluid how-it-works-container">
            <div class="row how-it-works-header">
                <div class="col-md-12 ">
                    <h1 class="how-it-works-title">How it Works!</h1>
                    <h6 class="how-it-works-subtitle ">
                        <p>Why choose takzin Transport?</p>
                    </h6>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 how-it-works-content">
                                <ol>
                                    <li>
                                        <span class="primary-color"><b>Instant Free Quotes</b></span> – Get instant and free quotes for your transport requirement at your fingertips.<br/><br/>
                                    </li>
                                    <li>
                                        <span class="primary-color"><b>Reliable Transport Network</b></span> – Takzin provides a reliable transport network which makes delivery of your logistics more efficiently.<br/><br/>
                                    </li>
                                    <li>
                                        <span class="primary-color"><b>Budget Pricing</b></span> - Takzin provides you transport on an economical and competitive market price.<br/><br/>
                                    </li>
                                    <li>
                                        <span class="primary-color"><b>Dedicated Team</b></span> - We have a dedicated support team that makes sure the transportation requirement is fulfilled with 100% customer satisfaction.
                                    </li>
                                </ol>
                                <p class="how-it-works-content-text">
                                    <strong>Book your transport from Dubai , Abu Dhabi , Sharjah , Ajman , Umm Al Quwain , Ras Al Khaimah , Fujairah and Al Ain with takzin.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div #transportQuote class="row transport-listing">
        <div class="col-md-12">
            <div class="container">
                <div class="row transport-quote-header">
                    <div class="col-md-12">
                        <h1>Request A Quote</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 search-form">
                        <form [formGroup]="transportFilterForm" (ngSubmit)="onSubmit(transportFilterForm.value)">
                            <div class="containe-fluid">
                                <div class="row">
                                    <div class="col-md-4">
                                        <!--Vehicle Type-->
                                        <div class="form-group">
                                            <label for="vehicleType" class="takzin-form-control-label required-label">
                                                Select Vehicle Type
                                            </label>
                                            <select id="vehicleType" type="text" class="form-control modal-select" (change)="onSelectionChanged('vehicleType')" formControlName="vehicleType" required>
                                                <option value="Select your option" disabled selected>Select your option</option>
                                                <option *ngFor="let vehicleType of vehicleTypes" value="{{vehicleType.type}}">{{vehicleType.value}}</option>
                                            </select>
                                            <div *ngIf="vehicleType!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                                                <div>
                                                    Vehicle Type is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!--Source-->
                                        <div class="form-group">
                                            <label for="source" class="takzin-form-control-label required-label">
                                                Select Pick Up Location
                                            </label>
                                            <select *ngIf="vehicleType!.value" id="source" type="text" class="form-control modal-select" (change)="onSelectionChanged('source')" formControlName="source" required>
                                                <option value="Select your option" disabled selected>Select your option</option>
                                                <option *ngFor="let location of sourceLocations" value="{{location}}">{{location}}</option>
                                                    
                                            </select>
                                            <div *ngIf="source!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                                                <div>
                                                    Pick Up Location is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!--Destination-->
                                        <div class="form-group">
                                            <label for="destination" class="takzin-form-control-label required-label">
                                                Select Drop Location
                                            </label>
                                            <select id="destination" type="text" class="form-control modal-select" (change)="onSelectionChanged('destination')" formControlName="destination" required>
                                                <option value="Select your option" disabled selected>Select your option</option>
                                                <option *ngFor="let location of sourceDestinationMapping[source!.value]" value="{{location}}">{{location}}</option>
                                            </select>
                                            <div *ngIf="destination!.invalid && showValidationMessages" class="alert alert-danger takzin-validation-msg">
                                                <div>
                                                    Drop Location is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <button type="submit" class="takzin-button-primary transport-form-button transport-check-rates">Check Rates</button>
                            <br />
                        </form>
                    </div>

                    <div class="container-fluid" *ngIf="this.transportData" scrollTo>
                        <div class="row transport-result">
                            <div class="col-md-3 truck-detail">
                                <!--Truck Type-->
                                <p>Vehicle Type:</p>
                                <span> {{this.displayVehicleType}}</span>
                            </div>
                            <div class="col-md-3 truck-detail">
                                <!--Source-->
                                <p>Pick Up Location:</p>
                                <span>{{transportData?.source}}</span>
                            </div>
                            <div class="col-md-3 truck-detail">
                                <!--Destination-->
                                <p>Drop Location:</p>
                                <span> {{transportData?.vehicleDetails[0]?.destination}}</span>
                            </div>
                            <div class="col-md-3 truck-detail transport-rate-box">
                                <!--Price Range-->
                                <p>Transport Rate:</p>
                                <p class="transport-rate-range">{{displayRate}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 ">
                                <button class="takzin-button-primary send-enquiry transport-quote-me" (click)="sendEnquiry()">Quote Me</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="displayNoDataMessage" class="col-md-12 no-search-message" scrollTo>
                        Request you to kindly send your requirement in detail at <a href="mailto:contact@takzin.com"> contact@takzin.com</a> or Call Us/Whatsapp at +971 56 783 1004/+971 50 237 3759 and we will get back to you shortly. Thank You for using
                        takzin.com!
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>