export class TransportEnquiryModel{
    name: string;
    email: string;
    companyName: string;
    contactNo: number;
    pickupLocation: string;
    pickupDate: string;
    pickupTime: string;
    dropLocation: string;
    subject: string;
    message: string;
}