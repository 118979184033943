export class ProductCard{
    emirate: string;
    area: string;
    storage: string;
    item: string;
    rangeValue: string;
    handlingInValue: string;
    handlingOutValue: string;
    pickupLocation: string;
    vehicleType: string;
    transportRate: string;
    isTransportEnabled: boolean;
}