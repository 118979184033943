import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductCard } from 'src/app/models';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GetQuoteUserInfoModel } from 'src/app/models/get-quotes-user-info.modal';
import { Router } from '@angular/router';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'takzin-get-quote-modal',
  templateUrl: './get-quote-modal.component.html',
  styleUrls: ['./get-quote-modal.component.scss']
})
export class GetQuoteModalComponent implements OnInit {

  filterForm: FormGroup;
  showValidationMessages: boolean = false;
  fileSizeValidator: boolean = false;
  fileTypeValidator: boolean = false; 

  file: File;
  fileName: String = '';

  
  get email() {
    return this.filterForm!.get('email');
  }

  get name() {
    return this.filterForm!.get('name');
  }

  get companyName() {
    return this.filterForm!.get('companyName');
  }

  get contactNo() {
    return this.filterForm!.get('contactNo');
  }

  get message() {
    return this.filterForm!.get('message');
  }

  get subject() {
    return this.filterForm!.get('subject');
  }

  get attachment(){
    return this.filterForm!.get('attachment');
  }

  constructor(
    private readonly dialogRef: MatDialogRef<GetQuoteModalComponent>,
    private readonly dialog: MatDialog,
    private router: Router,
    private el: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      let productCard = this.data.selectedData;
      this.filterForm = new FormGroup({
        email: new FormControl(this.data?.email, [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        ]),
        name: new FormControl('', [
          Validators.required,
        ]),
        companyName: new FormControl('', [
          Validators.required,
        ]),
        contactNo: new FormControl('', [
          Validators.pattern('^[0-9]*$')
        ]),
        subject: new FormControl(productCard.emirate+'-'+productCard.area+'-'+productCard.storage, [
          Validators.required,
        ]),
        message: new FormControl('', [
          Validators.required,
          Validators.maxLength(2000)
        ]),
        attachment: new FormControl()
    
      });
    }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onFileChange(event) {
    const fileList: FileList = event.target.files;
    this.file = fileList[0];
    if(this.file) {
      this.fileName = this.file.name; 
      this.fileTypeValidation(this.file);
      this.fileSizeValidation(this.file); 
    }
    
  }

  fileSizeValidation(file: File) {
    if (file) {
      const fileSize = file.size;
      const fileSizeInMB = fileSize/Math.pow(1024,2);
      if (fileSizeInMB > 5) {
       this.fileSizeValidator = true;
      } else {
        this.fileSizeValidator = false;
      }
    }
  }

  fileTypeValidation(file: File) {
    var allowedExtensions = ["xls","xlsx", "pdf", "doc", "docx", "png", "jpg","jpeg"];
    var extn = file.name.split(".").pop();
    if(allowedExtensions.indexOf(extn.toLowerCase()) > -1 ) {
      this.fileTypeValidator = false;
    }
    else {
      this.fileTypeValidator = true;
    }
  }


  onSubmit(userData: GetQuoteUserInfoModel) {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

    if (invalidControl) {
      invalidControl.focus();
    }
    const reader = new FileReader();
    this.showValidationMessages = true;
    if (this.filterForm.invalid || this.fileTypeValidator || this.fileSizeValidator) {
      this.showValidationMessages = true;
      return;
    }

    if(this.file != null) {
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        userData.attachment = reader.result;
        userData.fileName = this.fileName;
      }
    } else {
      userData.attachment = "";
      userData.fileName = "";
    }
    this.dialogRef.close(userData);
  }

  clickPrivacyPolicy(){
    this.dialogRef.close(false);
    this.router.navigate(['terms']);
  }

  OnFileClick(event){
    event.target.value = '';
  }
  
  onCloseClick() {
    if(this.file) {
    this.file= null;
    }
  }

}
