import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(
    private readonly seoService: SEOService
  ) { }

  ngOnInit(): void {
    const pageTitle = 'takzin | Terms and Conditions';
    this.seoService.updateTitle(pageTitle);

    const pageDescription = 'Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs. Terms and Conditions for using takzin.';
    this.seoService.updateDescription(pageDescription);
  }

}
