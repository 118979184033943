import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'takzin-send-enquiry-modal',
  templateUrl: './send-enquiry-modal.component.html',
  styleUrls: ['./send-enquiry-modal.component.scss']
})
export class SendEnquiryModalComponent implements OnInit {

  sendEnquiryForm: FormGroup;
  showValidationMessages: boolean = false;
  fileSizeValidator: boolean = false;
  fileTypeValidator: boolean = false; 
  minDate: Date = new Date();

  vehicleTypes = [
    { type: 'OneTonPickUp', value: '1 ton' },
    { type: 'ThreeTonPickUp', value: '3 ton' },
    { type: 'SevenTonPickUp', value: '7 ton' },
    { type: 'TenTonPickUp', value: '10 ton' },
    { type: 'ContainerTrailerForty', value: 'Container Flatbed 40"' },
    { type: 'CurtainTrailerForty', value: 'Curtain Trailer 40"' },
    { type: 'FlatBedTrailerForty', value: 'Flatbed Trailer 40"' },
  ];

  get email() {
    return this.sendEnquiryForm!.get('email');
  }

  get name() {
    return this.sendEnquiryForm!.get('name');
  }

  get companyName() {
    return this.sendEnquiryForm!.get('companyName');
  }

  get contactNo() {
    return this.sendEnquiryForm!.get('contactNo');
  }

  get message() {
    return this.sendEnquiryForm!.get('message');
  }

  get subject() {
    return this.sendEnquiryForm!.get('subject');
  }

  get pickupDate() {
    return this.sendEnquiryForm!.get('pickupDate');
  }

  get pickupTime() {
    return this.sendEnquiryForm!.get('pickupTime');
  }

  constructor(
    private readonly dialogRef: MatDialogRef<SendEnquiryModalComponent>,
    private readonly dialog: MatDialog,
    private router: Router,
    private el: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let transportData = this.data.selectedData;
    console.log(transportData.vehicleType);
    let vehicleType = this.vehicleTypes.filter(val => {
      return val.type.toLowerCase() === transportData.vehicleType.toString().toLowerCase()
    })[0];
    console.log(vehicleType);
    this.sendEnquiryForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]),
      name: new FormControl('', [
        Validators.required,
      ]),
      companyName: new FormControl('', [
        Validators.required,
      ]),
      pickupDate: new FormControl('', []),
      pickupTime: new FormControl('', []),
      contactNo: new FormControl('', [
        Validators.pattern('^[0-9]*$')
      ]),
      subject: new FormControl(vehicleType?.value+' - '+transportData?.source+' - '+transportData?.destination + ' ', [
        Validators.required,
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000)
      ])
    });
   }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(value) {
    console.log(value);
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

    if (invalidControl) {
      invalidControl.focus();
    }

    this.showValidationMessages = true;
    if (this.sendEnquiryForm.invalid || this.fileTypeValidator || this.fileSizeValidator) {
      this.showValidationMessages = true;
      return;
    }
    this.dialogRef.close(value);
  }

}
