export class TransportModel{
    source: string;
    vehicleDetails: VehicleDetail[];
}

export class VehicleDetail{
    extraToll: boolean;
    destination: string;
    rates: number[];
    vehicleType: string;
}