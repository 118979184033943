import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/services';

@Component({
  selector: 'takzin-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  requestType: string;
  constructor(
    private readonly seoService: SEOService,
    private readonly route: ActivatedRoute
  ) {
      this.requestType = this.route.snapshot.paramMap.get('requestType');
    }

  ngOnInit(): void {
    const pageTitle = 'takzin | Thank You';
    this.seoService.updateTitle(pageTitle);

    const pageDescription = 'Thank You for using takzin. Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs.';
    this.seoService.updateDescription(pageDescription);
  }

}
