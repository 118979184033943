import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GetQuoteUserInfoModel } from 'src/app/models/get-quotes-user-info.modal';
import { SEOService } from 'src/app/services';
import { EmailService } from 'src/app/services/email.service';
import { EmailTemplate } from 'src/assets/templates/email.template';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})


export class ContactUsComponent implements OnInit {

  filterForm: FormGroup;
  showValidationMessages: boolean = false;
  fileSizeValidator: boolean = false;
  fileTypeValidator: boolean = false; 

  file: File ;
  fileName: String = '';

  get email() {
    return this.filterForm!.get('email');
  }

  get name() {
    return this.filterForm!.get('name');
  }

  get companyName() {
    return this.filterForm!.get('companyName');
  }

  get contactNo() {
    return this.filterForm!.get('contactNo');
  }

  get message() {
    return this.filterForm!.get('message');
  }

  get subject() {
    return this.filterForm!.get('subject');
  }

  get attachment(){
    return this.filterForm!.get('attachment');
  }
  constructor(
    private readonly emailService: EmailService,
    private readonly router: Router,
    private readonly el: ElementRef,
    private readonly seoService: SEOService
    ) { 
      this.filterForm = new FormGroup({
        email: new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        ]),
        name: new FormControl('', [
          Validators.required,
        ]),
        companyName: new FormControl('', [
          Validators.required,
        ]),
        contactNo: new FormControl('', [
          Validators.pattern('^[0-9]*$')
        ]),
        subject: new FormControl('', [
          Validators.required,
        ]),
        message: new FormControl('', [
          Validators.required,
          Validators.maxLength(2000)
        ]),
        attachment: new FormControl()
      });
    }

  ngOnInit(): void {
    const pageTitle = 'Warehouse Storage and Transport in Dubai | Contact takzin';
    this.seoService.updateTitle(pageTitle);
    const pageDescription = 'Free Quote - No Contract - Low Prices - Save Time & Money - Convenient Warehouses. takzin is helping individuals & businesses find right warehouse storage and transport for their needs. Contact takzin.';
    this.seoService.updateDescription(pageDescription);
  }

  onFileChange(event) {
    const fileList: FileList = event.target.files;
    this.file = fileList[0];
    if(this.file) {
      this.fileName = this.file.name; 
      this.fileTypeValidation(this.file);
      this.fileSizeValidation(this.file); 
    }
    
  }
  fileSizeValidation(file: File) {
    if (file) {
      const fileSize = file.size;
      const fileSizeInMB = fileSize/Math.pow(1024,2);
      if (fileSizeInMB > 5) {
       this.fileSizeValidator = true;   
      } else {
        this.fileSizeValidator = false;
      }
    }
  }

  fileTypeValidation(file: File) {
    var allowedExtensions = ["xls","xlsx", "pdf", "doc", "docx", "png", "jpg","jpeg"];
    var extn = file.name.split(".").pop();
    if(allowedExtensions.indexOf(extn.toLowerCase()) > -1 ) {
      this.fileTypeValidator = false;
    }
    else{
      this.fileTypeValidator = true;
   
    }
  }

  OnFileClick(event){
    event.target.value = '';
  }
  onCloseClick() {
    if(this.file) {
    this.file= null;
    }
  }

  onSubmit(userInfo: GetQuoteUserInfoModel) {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid[formControlName]');
    if (invalidControl) {
      invalidControl.focus();
    }
    
    if (this.filterForm.invalid || this.fileSizeValidator || this.fileTypeValidator ) {
      this.showValidationMessages = true;
      return;
    }
  
    let contactTemplate = EmailTemplate.contactTemplate;
    contactTemplate = contactTemplate.replace('{Customer Name}', userInfo.name);
    contactTemplate = contactTemplate.replace('{Email}', userInfo.email);
    contactTemplate = contactTemplate.replace('{Company Name}', userInfo.companyName);
    contactTemplate = contactTemplate.replace('{Contact No}', userInfo.contactNo.toString());
    contactTemplate = contactTemplate.replace('{Message}', userInfo.message);
   
    const reader = new FileReader();
    if(this.file != null ) {
     
      reader.onload = () => {
        userInfo.attachment = reader.result;
        userInfo.fileName = this.fileName;
      }
      reader.readAsDataURL(this.file);
    }else {
      this.emailService.sendEmail(environment.contactEmail,  userInfo.subject, contactTemplate );
    }
    reader.onloadend=()=>{
      this.emailService.sendEmailWithAttachment(environment.contactEmail,  userInfo.subject, contactTemplate , userInfo.attachment, userInfo.fileName);
    }
    this.router.navigate(['thankyou', {requestType: "contactus"}]);
  }

}
