import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, } from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { DigitOnlyDirective } from '@uiowa/digit-only';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSelectModule } from '@angular/material/select';
import { 
  HomeComponent, 
  ProductsComponent,
  FooterComponent,
  ContactUsComponent,
  TermsComponent,
  GetRatesFilterModalComponent,
  GetQuoteModalComponent,
  ThankyouComponent,
  PrivacyComponent,
  BecomeVendorComponent,
  TransportListingComponent,
  ErrorPageComponent
} from './components';

import {
  ScrollToDirective
} from './directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmBoxComponent, LoadingComponent } from './components/shared';
import { HttpClientModule } from '@angular/common/http';
import { EmailService, QuoteEnquiryService, SEOService, TransportService, WarehouseService } from './services';
import { LocationStrategy, PathLocationStrategy  } from '@angular/common';
import { AboutComponent } from './components/about-us/about.component';
import { HeaderComponent } from './components/header/header.component';
import { SendEnquiryModalComponent } from './components/send-enquiry-modal/send-enquiry-modal.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    ProductsComponent,
    FooterComponent,
    ContactUsComponent,
    TermsComponent,
    PrivacyComponent,
    AboutComponent,
    GetRatesFilterModalComponent,
    ConfirmBoxComponent,
    GetQuoteModalComponent,
    ThankyouComponent,
    LoadingComponent,
    BecomeVendorComponent,
    TransportListingComponent,
    SendEnquiryModalComponent,
    ErrorPageComponent,
    ScrollToDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule, 
    MatSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'takzin'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule, // Only required for storage features
    AngularFireAnalyticsModule
  ],
  providers: [
    WarehouseService, 
    EmailService, 
    QuoteEnquiryService, 
    TransportService,
    SEOService,
    {provide : LocationStrategy , useClass: PathLocationStrategy  },
    DigitOnlyDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
