<div class="thankyou-img">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Thank You for choosing taḵzīn!</h1>
            </div>
        </div>
    </div>
</div>
<div class="container thankyou">
    <div class="col-md-12">
        <div class="row thankyou-message">
            <h1>Be on the lookout for an email from takzin.com</h1>
        </div>
        <div class="row" *ngIf="!requestType">
            <div class="thankyou-message-text">
                <p>We are currently in the process of reviewing your request and look forward to making this your best warehousing experience ever!</p>
                <p>Here's what you can expect next:</p>
                <ul>
                    <li>We may contact you shortly to verify your information so that we can best match you to vendors.
                    </li>
                    <li>Once the information is verified, we will send you an email with the quotes of all the warehouses that best fits your requirement within 24 hours.</li>
                </ul>
                <p>Our Business Hours are Saturday through Thursday 8 AM to 6 PM (Gulf Standard Time).</p>
            </div>
        </div>
        <div class="row" *ngIf="requestType?.toLowerCase() === 'contactus'">
            <div class="thankyou-message-text">
                <br />
                <p>Thank you for getting in touch!</p><br />
                <p>We appreciate you contacting takzin for your warehousing needs. We will get back to you shortly with all your queries answered.</p><br />
                <p>Our Business Hours are Saturday through Thursday 8 AM to 6 PM (Gulf Standard Time).</p><br />
            </div>
        </div>
        <div class="row" *ngIf="requestType?.toLowerCase() === 'becomevendor'">
            <div class="thankyou-message-text">
                <p>We are currently in the process of reviewing your request and look forward to making this your best warehousing experience ever!</p>
                <p>Here's what you can expect next:</p>
                <ul>
                    <li>We may contact you shortly to gather more information about your warehouse so that we can best match you to customers.
                    </li>
                </ul>
                <p>Our Business Hours are Saturday through Thursday 8 AM to 6 PM (Gulf Standard Time).</p>
            </div>
        </div>
        <div class="row" *ngIf="requestType?.toLowerCase() === 'transportation'">
            <div class="thankyou-message-text">
                <p>We are currently in the process of reviewing your request and look forward to making this as your best transportation experience ever!</p>
                <p>Here's what you can expect next:</p>
                <ul>
                    <li>We may contact you shortly to verify your information so that we can best match you to vendors.
                    </li>
                    <li>Once the information is verified, we will send you an email with the quotes that best fits your requirement within 24 hours.</li>
                </ul>
                <p>Our Business Hours are Saturday through Thursday 8 AM to 6 PM (Gulf Standard Time).</p>
            </div>
        </div>
        <div class="row">
            <div class="warningMessage">
                <h1>IMPORTANT: Be on the lookout for our email and please check your <span class="spam-text">SPAM folder</span> just in case.
                </h1>
            </div>
        </div>
    </div>
</div>