<div class="takzin-app">
    <app-takzin-header></app-takzin-header>
    <div class="spacer">
        &nbsp;
    </div>
    <div>
        <router-outlet></router-outlet>
        <span class="whatsapp-text">WhatsApp Us</span>
        <a href="https://wa.me/971567831004" class="whatsapp-logo" target="_blank">
        </a>
        <div class="back-to-top" (click)="scrollToTop()" *ngIf="windowScrolled">
            <i class="material-icons">north</i>
        </div>
    </div>
    <app-takzin-footer></app-takzin-footer>
</div>