<footer class="takzin-footer">
    <div class="container footer-container">
        <div class="row">
            <div class="col-md-6">
                <div class="row footer-row">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3">
                                <h4 class="footer-title">taḵzīn
                                    <span class="contact-mobile-view">
                                        P: <a href="tel:+971 56 783 1004">+971 56 783 1004</a><br /><a href="tel:+971 50 237 3759" class="second-phone">+971 50 237 3759</a><br /> E: <a href="mailto:contact@takzin.com">contact@takzin.com</a>
                                        </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row footer-row">
                    <div class="col-md-12">
                        <p>We provide progressive, competitive, instant and free quotes on mobile and web platforms for Warehousing and Transportation services.</p>
                    </div>
                </div>
                <div class="row footer-row">
                    <div class="col-md-12">
                        ©2021 | takzin.com | All Rights Reserved.
                    </div>
                </div>
                <div class="row footer-row">
                    <div class="col-md-12">
                        <a routerLink="/terms" routerLinkActive="active" target="_blank" alt="Terms of Service">Terms of Service</a> |
                        <a routerLink="/privacy" routerLinkActive="active" target="_blank" alt="Privacy Policy">Privacy Policy</a> |
                        <a routerLink="/contact-us" routerLinkActive="active" alt="Contact Us">Contact Us</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3 logo">
                <a href="https://www.facebook.com/Takzincom-102811395082970/" class="facebook-logo" target="_blank">
                </a>
                <a href="https://www.linkedin.com/company/takzin-com/" class="linkedin-logo" target="_blank">
                </a>
            </div>
            <div class="col-md-3 optional">
                <div class="row footer-row">
                    <div>
                        <h6 class="footer-title">Contact Us</h6>
                        <p>www.takzin.com<br /> P: +971 56 783 1004<br /> <span class='second-phone'>+971 50 237 3759</span><br /> E: <a href="mailto:contact@takzin.com">contact@takzin.com</a></p>
                        <button class="footer-button takzin-button-secondary" routerLink="/contact-us" routerLinkActive="active">Contact Us</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>