export class StorageSearchDto {
    id: string;
    email: string;
    lastSearchDate: Date;
    deleteFlag: boolean;
}

export class StorageDataDto{
    emirate: string;
    item: string;
    other: string;
    storage: string[];
    handling: string;
    land: string;
    valueAdded: string;
    duration: string;
    from: Date;
    to: Date;
    id:string;
    dateCreated: Date;
    deleteFlag: boolean;
}