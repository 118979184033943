import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import AppBar from "@material-ui/core/AppBar"
import { GetRatesFilterModalComponent } from '..';

@Component({
  selector: 'app-takzin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  hideGetStarted: boolean;
  constructor(public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) { 
      this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          if(val.url.includes('/transport-listing'))
          {
            this.hideGetStarted = true;
          }
          else
          {
            console.log(val.url);
            this.hideGetStarted = false;
          }
        }});
    }

  ngOnInit(): void {
  }

  openDialog(): void {
    
    if(document.URL.includes('/transport-listing'))
    {
      this.router.navigate(['transport-listing', {requestType: "quote"}])
      .then(() => {
        window.location.reload();
      });;
      return;
    }

    const dialogRef = this.dialog.open(GetRatesFilterModalComponent, {panelClass: 'takzin-modal'});

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.router.navigate(['listing']);
        localStorage.setItem('selection',JSON.stringify(result));

        if(this.router.url.includes('listing')){
          window.location.reload();
        }
      }
    });
  }

}
