import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { EmailTemplate } from 'src/assets/templates/email.template';

@Injectable()
export class EmailService {

  emailApiBaseUrl: string;

  constructor(private _http: HttpClient) {
    this.emailApiBaseUrl = environment.emailApiBaseUrl;
  }

  sendEmailWithAttachment(to: string, subject: string, body: string, attachment: any, fileName: String) {
    let url = this.emailApiBaseUrl + '/emailWithAttachment';
    let from = environment.adminEmail;
    body = EmailTemplate.baseTemplate.replace('{body}', body);
    let email = {
      "to": to,
      "from": from,
      "subject": subject,
      "html": body,
      "attachment": attachment,
      "filename": fileName,
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this._http.post(url, email, httpOptions)
      .toPromise()
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  sendEmail(to: string, subject: string, body: string) {
    let url = this.emailApiBaseUrl + '/email';
    let from = environment.adminEmail;
    body = EmailTemplate.baseTemplate.replace('{body}', body);
    let email = {
      "to": to,
      "from": from,
      "subject": subject,
      "html": body
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this._http.post(url, email, httpOptions)
      .toPromise()
      .then(res => {
        console.log("Success Message")
        console.log(res)
      })
      .catch(err => {
        console.log("Error Message")
        console.log(err)
      })
  }
}